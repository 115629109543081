import React, { useContext, useState, useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./Navbar.css";
import mixpanel from "mixpanel-browser";
import PNGIMG from "../../../../Utils/Images/default_user.png";
import { useNavigate } from "react-router-dom";
import { siteControlContext } from "../../../../Context/SiteControlsState";
import { RiCoinsFill } from "react-icons/ri";
import { FaRegClock } from "react-icons/fa";
import { linkedinContext } from "../../../../Context/LinkedinState";
import { PurchaseCredits } from "../../../../Pages/Dashboard/PurchaseCredits/PurchaseCredits";

function Navbar({ ChangeModalState, ModalState, userData, alternateInfo }) {
  const navigate = useNavigate();

  const { shortSidebar } = useContext(siteControlContext);
  const { verifiedData } = useContext(linkedinContext);
  const [openCredits, setOpenCredits] = useState(false);
  const innerDivRef = useRef(null);

  // Function to handle clicks outside of the inner div
  const handleClickOutside = (event) => {
    if (innerDivRef.current && !innerDivRef.current.contains(event.target)) {
      setOpenCredits(false);
    }
  };

  useEffect(() => {
    if (openCredits) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openCredits, setOpenCredits]);

  // handles the openeing of the creator modal
  const handleModalOpening = (e) => {
    e?.stopPropagation();
    mixpanel.track("Event Header Profile ");
    ModalState ? ChangeModalState(false) : ChangeModalState(true);
  };

  if (shortSidebar) {
    return null;
  }
  return (
    <>
      {openCredits && (
        <div className="EventsnavbarChipsPopup">
          <div ref={innerDivRef} className="EventsnavBarChipsPopupContainer">
            <PurchaseCredits setOpenCredits={setOpenCredits} />
          </div>
        </div>
      )}
      <div className="navbar_outside_container">
        {window.screen.width < 600 && (
          <img
            onClick={() => {
              navigate("/");
              mixpanel.track("header logo");
            }}
            src={require("../../../../Utils/Images/logo-invite-only.png")}
            alt=""
            className="logo_sidebar"
          />
        )}

        <LazyLoadImage
          effect="blur"
          className="creators_navbar_image"
          onClick={handleModalOpening}
          src={
            alternateInfo?.profile
              ? alternateInfo?.profile
              : userData?.photo
              ? userData?.photo
              : PNGIMG
          }
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = PNGIMG;
          }}
        />

        {verifiedData?.planActivated && (
          <>
            <button
              onClick={() => setOpenCredits(true)}
              className="navbar_dashboard_button01"
            >
              <RiCoinsFill />{" "}
              {new Date(Date.now()) <
              new Date(verifiedData?.planActivated?.expireAt)
                ? verifiedData?.planActivated?.usageID?.totalAvailableCredits
                : 0}{" "}
              Credits Left
            </button>

            <button className="navbar_dashboard_button01">
              <FaRegClock />{" "}
              {new Date(Date.now()) <
              new Date(verifiedData?.planActivated?.expireAt) ? (
                <>
                  {Math.ceil(
                    (new Date(verifiedData?.planActivated?.expireAt) -
                      Date.now()) /
                      (1000 * 24 * 60 * 60)
                  )}{" "}
                  days left
                </>
              ) : (
                "Plan Expired"
              )}
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default Navbar;
