import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import "./Users.css";
import ServiceContext from "../../../../Context/services/serviceContext";
import { userContext } from "../../../../Context/UserState";
import { useNavigate, useParams } from "react-router-dom";
import { creatorContext } from "../../../../Context/CreatorState";
import { LoadTwo } from "../../../Modals/Loading";
import { SuperSEO } from "react-super-seo";
import Moment from "moment";
import { BsArrowLeftShort } from "react-icons/bs";
import Star from "../../../../Utils/Icons/starsGroup.svg";
import { Table2 } from "../Create Services/InputComponents/fields_Labels";
import { Button4, Button5 } from "../Create Services/InputComponents/buttons";
import { FaFileCsv } from "react-icons/fa";
import { CSVLink } from "react-csv";
import * as htmlToImage from "html-to-image";
import isUrl from "is-url";
import { host } from "../../../../config/config";
import { toast } from "react-toastify";
import { RxEyeOpen } from "react-icons/rx";
import { FiSend } from "react-icons/fi";

function Users(props) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { getUserDetails, allUserDetails } = useContext(creatorContext);
  const {
    compareJWT,
    geteventinfo,
    eventInfo,
    getLeaderBoardData,
    UploadCertificate,
    updateEvent,
  } = useContext(ServiceContext);
  const { updateCertificateforUser, SendCertificateforUser } =
    useContext(userContext);
  const [openLoading, setopenLoading] = useState(false);
  const [approvedUser, setapprovedUser] = useState(false); // check if user searching is appropriate
  const [category, setCategory] = useState("Registered_Users");
  const [leaderBoardData, setLeaderBoardData] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCertificateSend, setIsCertificateSend] = useState(false);
  const [CurrentGenerating, SetCurrentGenerating] = useState([]);

  const [certificatePreviewData, setCertificatePreviewData] = useState({
    open: false,
  });
  const [markedAttendee, setMarkedAttendee] = useState([]);
  let certData = {
    1: "#FEF2F2",
    2: "linear-gradient(217deg, #DC2626 12.1%, #1000C3 88.03%)",
  };
  const certificateRef = useRef(null);

  //useeffect to chang para of the certificate
  useEffect(() => {
    let doc = document.querySelector("#certificateLabel01");
    let a = eventInfo?.event?.certificateData?.para?.replace(
      "(organisers Name)",
      eventInfo?.event?.certificateData?.name
    );

    if (doc) {
      doc.innerHTML = "";
      doc.innerHTML = a;
    }
  }, [eventInfo, certificatePreviewData]);

  // useffect for the sign of certificate
  useEffect(() => {
    let doc = document.querySelector("#certificate_signature01");
    if (doc) {
      doc.style.bottom =
        eventInfo?.event?.certificateData?.signStyle?.bottom + "px";
      doc.style.width =
        eventInfo?.event?.certificateData?.signStyle?.width + "px";
    }
  }, [eventInfo, certificatePreviewData]);
  // Checking if the user is only able to check its data not others-------------------
  useEffect(() => {
    props.progress(0);
    geteventinfo(slug).then((e) => {
      compareJWT(e[0]?._id).then((e) => {
        if (e) {
          setapprovedUser(true);
          props.progress(100);
        } else {
          navigate("/dashboard/mycontents");
        }
      });
    });
  }, []);
  useEffect(() => {
    // Get the current URL search parameters
    const params = new URLSearchParams(window.location.search);

    // Access query parameters
    const param1 = params.get("category");
    setCategory(param1 || "Registered_Users");
  }, []);

  useEffect(() => {
    // Loading mixpanel ------
    // mixpanel.track("Page Visit");
    geteventinfo(slug).then((id) => {
      if (!id[0]) {
        // handles any irregular slug
        navigate("/");
        return null;
      }
      getLeaderBoardData(id[1], localStorage.getItem("isUser") === "").then(
        (e) => {
          if (e?.success) {
            setLeaderBoardData(e);
          }
        }
      );
    });
  }, []);

  useEffect(() => {
    setopenLoading(true);
    getUserDetails(eventInfo?.event?._id).then((e) => {
      setopenLoading(false);
    });
    if (eventInfo?.event?.markedAttendee?.length > 0) {
      setMarkedAttendee(eventInfo?.event?.markedAttendee);
    }
    setIsCertificateSend(eventInfo?.event?.isCertificateSend);
  }, [eventInfo]);

  //useeffect to update certitifcate link in markedattendee
  useEffect(() => {
    const allMarkedItems = markedAttendee?.map((item) => item?._id);
    setMarkedAttendee(
      allUserDetails?.filter((item) => allMarkedItems?.includes(item?._id))
    );
  }, [allUserDetails]);

  const renderdate1 = (date) => {
    let a = new Date(date);
    let b = a.toISOString();
    const splity = b.split("T");
    return splity[0];
  };

  const renderdate2 = (date) => {
    let a = new Date(date);
    let b = a.toString();
    const splity = b.split(" ");
    return splity[4].slice(0, 8);
  };

  const handleCertificatePreview = (name) => {
    setCertificatePreviewData({
      open: true,
    });
    let doc = document.querySelector(".text_04_certificate_01");
    if (doc) {
      doc.innerHTML = "";
      doc.innerHTML = name;
    }
  };

  const handleSelectAll = async () => {
    if (markedAttendee?.length === allUserDetails?.length) {
      return setMarkedAttendee([]);
    }
    setMarkedAttendee(allUserDetails);
    for (let i = 0; i < allUserDetails?.length; i++) {
      const value = allUserDetails[i];
      if (!value?.certificateLink) {
        setIsGenerating(true);
        await generateCertificate(value);
      }
    }
  };
  const toggleMarkedAttendee = (value) => {
    const items = markedAttendee?.map((item) => item?._id);
    if (items?.includes(value?._id)) {
      setMarkedAttendee(
        markedAttendee?.filter((item) => {
          return item?._id !== value._id;
        })
      );
    } else {
      setMarkedAttendee((prev) => {
        return [...prev, value];
      });
      if (!value?.certificateLink) {
        setIsGenerating(true);
        generateCertificate(value);
      }
    }
  };
  const getImageDataUri = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const saveTheCertificate = async ({ showSign, name }) => {
    try {
      let doc = document.querySelector(".text_04_certificate_01");
      if (doc) {
        doc.innerHTML = "";
        doc.innerHTML = name;
      }
      let imgtag = document.getElementById(`certificate_signature01`);
      if (showSign) {
        let dataURI;
        if (isUrl(showSign)) {
          dataURI = `${host}/api/file/proxyImage?imageUrl=${showSign}`;
          // dataURI = showSign;
        } else {
          dataURI = await getImageDataUri(showSign);
        }
        imgtag.src = dataURI;
      }

      const element = certificateRef.current;
      if (!element) return;

      const blob = await htmlToImage.toBlob(element);
      // const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "certificate.png", { type: "image/png" });
      try {
        // const file = new File([blob], "banner2.png", { type: blob.type });
        const data1 = new FormData();
        data1.append("file", file);
        let banner = await UploadCertificate(data1);
        return banner;
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
    }
  };

  const generateCertificate = async (attendee) => {
    const banner = await saveTheCertificate({
      name: attendee?.userID?.name,
      showSign: eventInfo?.event?.certificateData?.sign,
    });
    if (banner?.success) {
      const result = await updateCertificateforUser(
        eventInfo?.event?._id,
        attendee?.userID?._id,
        banner?.result?.Location
      );
      if (result) {
        await getUserDetails(eventInfo?.event?._id).then((e) => {
          setIsGenerating(false);
        });
      } else {
        console.log(result);
      }
    } else {
      toast.error("Some Error Ocuured while generating certificate", {
        autoClose: 1500,
      });
    }
  };

  const date = Moment(eventInfo?.event?.createdOn).format().split("T")[0];

  const time = Moment(eventInfo?.event?.createdOn)
    .format()
    .split("T")[1]
    .split("+")[0];

  // hides the email -----------------------
  // const hiddenEmail = (email) => {
  //   let email2 =
  //     email?.split("@")[0].length > 6
  //       ? email?.split("@")[0].substr(0, 5) + "....@" + email?.split("@")[1]
  //       : email?.split("@")[0].substr(0, 3) + "....@" + email?.split("@")[1];

  //   return email2;
  // };
  const handleCategory = (category) => {
    setCategory(category);
  };
  const totalAmount = allUserDetails.reduce(
    (acc, ele) => acc + (ele?.amount || 0),
    0
  );
  const getTableHeadArray = () => {
    if (category === "Registered_Users") {
      return [
        "Name",
        "Email ID",
        "Phone Number",
        "Location",
        "Registered on",
        "Amount Paid",
        "No. of WhatsApp Sent",
        "No. of Emails Sent",
        "Referred By",
      ];
    } else if (category === "Activity_Email_WA") {
      return [
        "Name",
        "Email ID",
        "Phone Number",
        "Location",
        "Registered on",
        "Amount Paid",
        "No. of WhatsApp Sent",
        "No. of Emails Sent",
        "Referred By",
      ];
    } else if (category === "LeaderBoard") {
      return ["S.no", "Email", "Name", "Referrals"];
    } else if (category === "mark_attendance") {
      return ["Name", "Email", "Phone Number", "Certificate", "Mark attedance"];
    } else {
      return [
        "Name",
        "Email ID",
        "Phone Number",
        "Location",
        "Registration Attempted",
        "Status",
      ];
    }
  };
  const getGridConfig = () => {
    if (category === "Registered_Users") {
      return "11% 21% 11% 8% 7% 7% 13% 13% 7%";
    } else if (category === "Activity_Email_WA") {
      return "6% 15% 10% 10% 10% 10% 14% 12% 8%";
    } else if (category === "LeaderBoard") {
      return "10% 20% 20% 10%";
    } else {
      return "6% 25% 20% 15% 20% 14%";
    }
  };
  const [csvData, setCsvData] = useState([]);

  const handleExportCsv = () => {
    let data;
    if (category !== "LeaderBoard") {
      data = allUserDetails
        ?.filter((e) => {
          return category === "Registered_Users"
            ? e?.status === 1
            : category === "Abandoned_Cart"
            ? e?.status === 0
            : !e;
        })
        ?.map((elem) => {
          if (category === "Registered_Users") {
            return {
              Name: elem?.userID?.name || "--",
              Email: elem?.userID?.email || "--",
              Mobile_Number: elem?.userID?.phoneNumber || "--",
              location: elem?.userID?.location?.city || "---",
              Order_Date:
                renderdate1(elem?.orderDate) +
                "," +
                renderdate2(elem?.orderDate),
              Amount: elem?.amount || "--",
              // whatsapp : elem?.whatsapp || "--",
              // Email : elem?.email || "--",
              Reffered_from: elem?.referredFrom ? "Yes" : "No",
            };
          }

          // else if (category === "Activity_Email_WA"){
          //   return {
          //     Name: elem?.userID?.name || "--",
          //     Email: elem?.userID?.email || "--",
          //     Mobile_Number:elem?.userID?.phoneNumber || "--",
          //     location : elem?.userID?.location?.city ||  "---",
          //     Order_Date :               <span>
          //                     {renderdate1(elem?.orderDate)}
          //                     <br></br>
          //                     {renderdate2(elem?.orderDate)}
          //                   </span> || "--",
          //     Amount : elem?.amount || "--",
          //     whatsapp : elem?.whatsapp || "--",
          //     Email : elem?.email || "--",
          //     Reffered_from :elem?.referredFrom ? "Yes" : "No"
          //   }
          // }
          else {
            return {
              Name: elem?.userID?.name || "--",
              Email: elem?.userID?.email || "--",
              Mobile_Number: elem?.userID?.phoneNumber || "--",
              location: elem?.userID?.location?.city || "---",
              Order_Date:
                renderdate1(elem?.orderDate) +
                "," +
                renderdate2(elem?.orderDate),
              Status: elem?.status || "--",
            };
          }
        });
    } else {
      data = leaderBoardData?.data
        ?.filter((item) => !item?.isdummy)
        ?.map((item, index) => {
          return {
            Email: item?.email,
            Name: item?.name,

            Points: item?.points,
          };
        });
    }

    // Update the CSV data state
    setCsvData(data);
  };

  const handleSendCertificates = async () => {
    try {
      if (markedAttendee?.length <= 0) {
        return toast.error("Please select a user before proceeding.", {
          autoClose: 1500,
        });
      }
      setopenLoading(true);
      for (let i = 0; i < markedAttendee?.length; i++) {
        const value = markedAttendee[i];
        if (!value?.certificateLink) {
          setIsGenerating(true);
          generateCertificate(value);
        }
        let result = await SendCertificateforUser(
          eventInfo?.event?._id,
          value?.userID?._id,
          value?.certificateLink
        );
        if (!result) {
          setopenLoading(false);
          return toast.error("Error in Sending Mail", { autoClose: 1500 });
        }
      }
      setopenLoading(false);
      setIsCertificateSend(true);
      toast.success("Mails Send Successfully", { autoClose: 1500 });
      let result = await updateEvent(eventInfo?.event?._id, {
        markedAttendee: markedAttendee,
        isCertificateSend: true,
      });

      if (result) {
        setopenLoading(false);
        return toast.success("Successfully saved the details", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        setopenLoading(false);
        return toast.error("Error in Saving Draft", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } catch (error) {
      setopenLoading(false);
      return toast.error("Error in Sending mails", {
        position: "top-center",
        autoClose: 1500,
      });
    }
  };

  const handleSaveDraft = async () => {
    try {
      setopenLoading(true);
      if (markedAttendee?.length <= 0) {
        setopenLoading(false);
        return toast.error("Please select a user before proceeding.", {
          autoClose: 1500,
        });
      }
      let result = await updateEvent(eventInfo?.event?._id, {
        markedAttendee: markedAttendee,
      });

      if (result) {
        setopenLoading(false);
        return toast.success("Successfully saved the details", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        setopenLoading(false);
        return toast.error("Error in Saving Draft", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } catch (error) {
      setopenLoading(false);
      return toast.error("Error in Saving Draft", {
        position: "top-center",
        autoClose: 1500,
      });
    }
  };

  useEffect(() => {
    handleExportCsv();
  }, [allUserDetails, category]);
  return (
    <>
      {(openLoading || !approvedUser) && <LoadTwo open={openLoading} />}
      <div
        style={{ zIndex: certificatePreviewData?.open ? "100" : "-10" }}
        className="logout_model_logout"
      >
        <section className="certificate_preview_system">
          <div
            className="event_certificate_outside_container"
            style={{
              transform: `scale(${1})`,
              transformOrigin: "center" ?? "0 0",
            }}
            ref={certificateRef}
          >
            <div
              className="elipse01_color_layer_certificate"
              style={{
                background: eventInfo?.event?.certificateData?.certificate,
              }}
            ></div>
            <div
              className="elipse02_color_layer_certificate"
              style={{
                background: eventInfo?.event?.certificateData?.certificate,
              }}
            ></div>

            <section>
              <div className="heading_certificate_01">
                <img
                  src={Star}
                  style={{
                    position: "absolute",
                    left: "-25px",
                    top: "-14px",
                  }}
                  alt=""
                />
                <h1 className="text_01_certificate_01">CERTIFICATE</h1>

                <span className="text_02_certificate_01">of Completion</span>
                <img
                  src={Star}
                  style={{
                    position: "absolute",
                    right: "-19px",
                    bottom: "-10px",
                  }}
                  alt=""
                />
              </div>

              <span className="text_03_certificate_01">
                This is to certify that
              </span>

              <div className="naming_certificate_01">
                <span
                  id="namingcertificate01"
                  className="text_04_certificate_01"
                >
                  Ravi Ahirwar
                </span>

                <div className="horizonal_underline_certificate01"></div>

                <img
                  src={Star}
                  style={{
                    position: "absolute",
                    right: "-24px",
                    top: "-8px",
                  }}
                  alt=""
                />
              </div>

              <div
                className="certificate_label_01"
                id="certificateLabel01"
              ></div>

              <div className="designation_certificate_01">
                <span>
                  <span className="text_07_certificate_01">
                    Date :{" "}
                    {new Date(
                      eventInfo?.event?.startDate
                    )?.toLocaleDateString()}
                  </span>
                  <div className="horizonal_underline_certificate01"></div>
                </span>

                <span className="text_07_certificate_01">
                  <img
                    src={eventInfo?.event?.certificateData?.sign}
                    alt="signature"
                    id="certificate_signature01"
                  />
                  <div className="horizonal_underline_certificate01"></div>
                </span>
              </div>

              <div className="branding_certificate_01">
                Powered By :{" "}
                <img
                  src={require("../../../../Utils/Images/logo-events.png")}
                />
              </div>
            </section>
          </div>

          <Button4
            text="Close"
            onClick={() => {
              setCertificatePreviewData({
                ...certificatePreviewData,
                open: false,
              });
            }}
          />
        </section>
      </div>

      {/* it can be seen only if the user is approved ----------------------------- */}
      {approvedUser && (
        <div className="servicelist-wrapper">
          {/* MObile ui navbar ---------------- */}
          {window.screen.width < 600 && (
            <section className="navbar_ui_covering_section_mobile_active">
              <BsArrowLeftShort
                size={22}
                onClick={() => {
                  navigate(-1);
                }}
              />
              User List for {eventInfo?.event?.sname}
            </section>
          )}

          {window.screen.width > 600 && (
            <section
              className="service_stats_page_title_section"
              style={{ marginBottom: "20px" }}
            >
              <h1 style={{ color: "#E2E8F0" }}>
                Event Name :{" "}
                <span style={{ fontWeight: "400", fontSize: "" }}>
                  {eventInfo?.event?.sname.slice(0, 40)}...
                </span>
              </h1>
            </section>
          )}
          <div className="service_category_selection_buttons">
            <Button5
              className={category === "Registered_Users" ? "Selected" : ""}
              onClick={() => {
                handleCategory("Registered_Users");
              }}
              text={"Registered Users"}
            ></Button5>
            {/* <Button5
              className={category === "Activity_Email_WA" ? "Selected" : ""}
              onClick={() => {
                handleCategory("Activity_Email_WA");
              }}
              text={"Activity ( Email  & WA )"}
            ></Button5> */}
            <Button5
              className={category === "Abandoned_Cart" ? "Selected" : ""}
              onClick={() => {
                handleCategory("Abandoned_Cart");
              }}
              text={"Abandoned Cart"}
            ></Button5>
            <Button5
              className={category === "LeaderBoard" ? "Selected" : ""}
              onClick={() => {
                handleCategory("LeaderBoard");
              }}
              text={"Referral Leaderboard"}
            ></Button5>
            <Button5
              className={category === "mark_attendance" ? "Selected" : ""}
              onClick={() => {
                handleCategory("mark_attendance");
              }}
              text={"Mark Attendance"}
            ></Button5>
          </div>
          <div className="service_category_header_breaker"></div>
          <div className="service_userlist_header">
            <h1
              style={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "24.2px",
                color: "#F8FAFC",
              }}
            >
              {category === "Registered_Users"
                ? "Registered Users"
                : category === "Activity_Email_WA"
                ? "Activity ( Email  & WA )"
                : category === "Abandoned_Cart"
                ? "Abandoned Cart"
                : category === "LeaderBoard"
                ? "Referral Leaderboard"
                : category === "mark_attendance"
                ? "Mark Attendance"
                : ""}
            </h1>
            <div
              className="service_export_csv_button"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#94A3B8",
                  lineHeight: "19.36px",
                  width: "942px",
                }}
              >
                {category === "Registered_Users" ? (
                  "Registered Users for this Event"
                ) : category === "Activity_Email_WA" ? (
                  "Track Email & WA sent to users"
                ) : category === "Abandoned_Cart" ? (
                  "Below are users who expressed interest but didn't complete registration. Feel free to reach out to encourage them to register. For technical assistance, contact us at +918799710137."
                ) : category === "LeaderBoard" ? (
                  "Check out the top referrers for your event - see who has invited the most attendees through their referral link."
                ) : category === "mark_attendance" ? (
                  <div>
                    Select the participants who attended the event and send out
                    ready-made certificates to your attendees. <br /> Please
                    note: Certificates can only be sent once to the selected
                    list of attendees from the registered participants..
                  </div>
                ) : (
                  ""
                )}
              </h2>
              {category === "mark_attendance" ? (
                <button onClick={handleSelectAll}>Select All</button>
              ) : (
                <button>
                  <CSVLink
                    data={csvData}
                    filename={`user_data_${eventInfo?.event?.sname}_${category
                      ?.toLowerCase()
                      .replace(/\s/g, "_")}.csv`}
                  >
                    <FaFileCsv /> Export
                  </CSVLink>
                </button>
              )}
            </div>
          </div>
          <div style={{ overflowX: "auto" }}>
            {category !== "LeaderBoard" && category !== "mark_attendance" && (
              <Table2
                headArray={getTableHeadArray()}
                bodyArray={allUserDetails
                  ?.filter((e) => {
                    return category === "Registered_Users"
                      ? e?.status === 1
                      : category === "Abandoned_Cart"
                      ? e?.status === 0
                      : !e;
                  })
                  ?.map((elem, i) => {
                    return category === "Registered_Users"
                      ? [
                          elem?.userID?.name ? elem?.userID?.name : "--",
                          elem?.userID?.email ?? "--",
                          elem?.userID?.phoneNumber ?? "--",
                          elem?.userID?.location?.city ?? "--",
                          <span>
                            {renderdate1(elem?.orderDate)}
                            <br></br>
                            {renderdate2(elem?.orderDate)}
                          </span>,
                          elem?.amount,
                          elem?.whatsapp ? elem?.whatsapp : "--",
                          elem?.email ? elem?.email : "--",
                          elem?.referredFrom ? "Yes" : "No",
                        ]
                      : category === "Activity_Email_WA"
                      ? [
                          elem?.userID?.name ? elem?.userID?.name : "--",
                          elem?.userID?.email ?? "--",
                          elem?.userID?.phoneNumber ?? "--",
                          elem?.userID?.location?.city ?? "--",
                          <span>
                            {renderdate1(elem?.orderDate)}
                            <br></br>
                            {renderdate2(elem?.orderDate)}
                          </span>,
                          elem?.amount,
                          elem?.whatsapp ? elem?.whatsapp : "--",
                          elem?.email ? elem?.email : "--",
                          elem?.referredFrom ? "Yes" : "No",
                        ]
                      : category === "Abandoned_Cart"
                      ? [
                          elem?.userID?.name ? elem?.userID?.name : "--",
                          elem?.userID?.email ?? "--",
                          elem?.userID?.phoneNumber ?? "--",
                          elem?.userID?.location?.city ?? "--",
                          <span>
                            {renderdate1(elem?.orderDate)}
                            <br></br>
                            {renderdate2(elem?.orderDate)}
                          </span>,
                          elem?.status === 0 ? "Unsuccessful" : "--",
                        ]
                      : "";
                  })}
                gridConfig={getGridConfig()}
              />
            )}
            {category === "LeaderBoard" && (
              <Table2
                headArray={getTableHeadArray()}
                bodyArray={leaderBoardData?.data
                  ?.filter((item) => !item?.isdummy)
                  ?.map((item, index) => [
                    index + 1,
                    item?.email,
                    item?.name,
                    item?.points,
                  ])}
                gridConfig={getGridConfig()}
              />
            )}
            {category === "mark_attendance" &&
              (eventInfo?.event?.certificateData ? (
                <Table2
                  headArray={getTableHeadArray()}
                  bodyArray={allUserDetails
                    ?.filter((e) => {
                      return e?.status === 1;
                    })
                    ?.map((item, index) => [
                      item?.userID?.name ? item?.userID?.name : "--",
                      item?.userID?.email ?? "--",
                      item?.userID?.phoneNumber ?? "--",
                      <div style={{ position: "relative" }}>
                        {markedAttendee
                          ?.map((item) => item?._id)
                          .includes(item?._id) && item?.certificateLink ? (
                          <span
                            onClick={() => {
                              // mixpanel.track("Certificate Preview");
                              handleCertificatePreview(item?.userID?.name);
                            }}
                            style={{
                              display: "flex",
                              gap: "10px",
                              color: "#10B981",
                              cursor: "pointer",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            View Certificate <RxEyeOpen />{" "}
                          </span>
                        ) : (
                          <span style={{ color: "#F59E0B" }}>
                            {" "}
                            Certificate not generated{" "}
                          </span>
                        )}
                        {isGenerating && markedAttendee?.includes(item) && (
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              top: 0,
                              left: 0,
                              display: "flex",
                              justifyContent: "center",
                              zIndex: "10",
                              background: "#131825",
                            }}
                          >
                            <div className="tableCellLoader"></div>
                          </div>
                        )}
                      </div>,
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            border: "0.1px solid #E2E8F0",
                            color: "#10B981",
                            borderRadius: "4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleMarkedAttendee(item)}
                        >
                          {markedAttendee
                            ?.map((item) => item?._id)
                            .includes(item?._id)
                            ? "✓"
                            : ""}
                        </div>
                      </div>,
                    ])}
                  gridConfig={getGridConfig()}
                />
              ) : (
                <div className="service_userlist_no_data_message">
                  Please Select a certificate to continue <br />
                  Go to My Events to Customize your Certificate
                </div>
              ))}
          </div>
          {category === "mark_attendance" &&
            (eventInfo?.event?.certificateData ? (
              <div className="service_userlist_footer service_category_selection_buttons">
                <button
                  onClick={handleSaveDraft}
                  className="serviceListFooterSaveDraftButton"
                >
                  Save Draft
                </button>
                <Button5
                  className="Selected"
                  onClick={isCertificateSend ? "" : handleSendCertificates}
                  text={`${
                    isCertificateSend ? "Certificates Sent" : "Send Certificate"
                  }`}
                  icon={isCertificateSend ? "" : <FiSend size={16} />}
                ></Button5>
              </div>
            ) : (
              ""
            ))}
        </div>
      )}

      <SuperSEO title="Anchors - User Downloads" />
    </>
  );
}

export default Users;
