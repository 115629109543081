import React from "react";
import { Helmet } from "react-helmet";

function Seo({
  title = "anchors|Events - Home for Creators | Monetize Content, Skills & Time",
  description = "Welcome to anchors|Events – the ultimate platform for creators to monetize their audience through captivating and engaging events! Whether you're a social media influencer, blogger, vlogger, or podcaster, anchors|Events empowers you to take your content and career to new heights by hosting immersive and profitable events for your dedicated audience. With a suite of powerful tools and features tailored specifically for creators, anchors|Events offers unparalleled support and guidance to help you unlock the full potential of the creator's economy. Join our vibrant community of creators today and start monetizing your content, skills, and time like never before!",
  imgUrl = "https://www.anchors.in:5000/api/file/anchors-logo.jpeg",
  keywords = "Join Anchors for creators,Exclusive community for creators,Monetize your content on Anchors,Creator economy platform,Connect with fellow creators on Anchors,Manage and monetize your content on Anchors,Unlock your potential on Anchors,Anchors for social media influencers, bloggers, vloggers, and podcasters,Collaborate with creators on Anchors,Join the Anchors community and grow your career as a creator",
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={`https://events.anchors.in/${window.location.pathname}`}
      />
      <meta name="keywords" content={keywords} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta
        property="og:url"
        content={`https://events.anchors.in/${window.location.pathname}`}
      />
      <meta property="og:site_name" content="anchors" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:type" content="product" />
      <meta property="og:locale" content="en_US" />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="anchors.in" />
      <meta
        property="twitter:url"
        content={`https://events.anchors.in/${window.location.pathname}`}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
    </Helmet>
  );
}

export default Seo;
