import React from "react";

const Image3 = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 18V12H17.5V18H15.5V14H3.5V18H1.5ZM2 11C1.58333 11 1.22933 10.8543 0.938 10.563C0.646667 10.2717 0.500667 9.91733 0.5 9.5C0.5 9.08333 0.646 8.72933 0.938 8.438C1.23 8.14667 1.584 8.00067 2 8C2.41667 8 2.771 8.146 3.063 8.438C3.355 8.73 3.50067 9.084 3.5 9.5C3.5 9.91667 3.35433 10.271 3.063 10.563C2.77167 10.855 2.41733 11.0007 2 11ZM4.5 11V2C4.5 1.45 4.696 0.979333 5.088 0.588C5.48 0.196667 5.95067 0.000666667 6.5 0H12.5C13.05 0 13.521 0.196 13.913 0.588C14.305 0.98 14.5007 1.45067 14.5 2V11H4.5ZM17 11C16.5833 11 16.2293 10.8543 15.938 10.563C15.6467 10.2717 15.5007 9.91733 15.5 9.5C15.5 9.08333 15.646 8.72933 15.938 8.438C16.23 8.14667 16.584 8.00067 17 8C17.4167 8 17.771 8.146 18.063 8.438C18.355 8.73 18.5007 9.084 18.5 9.5C18.5 9.91667 18.3543 10.271 18.063 10.563C17.7717 10.855 17.4173 11.0007 17 11ZM6.5 9H12.5V2H6.5V9Z" fill="#F8F8F8"/>
        </svg>
    )
}

export default Image3;