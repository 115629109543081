import { MdLogout, MdOutlineKeyboardBackspace } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import "./PricingCheckOut.css";

import { ChangeEvent, useEffect, useState, useContext } from "react";
import { LoadThree } from "../Modals/Loading";
import { EventsNavbar } from "../Layouts/Navbar Creator/Navbar";
import { TextField1 } from "../Editor/New UI/Create Services/InputComponents/fields_Labels";
import { Button4 } from "../Editor/New UI/Create Services/InputComponents/buttons";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { siteControlContext } from "../../Context/SiteControlsState";
import { paymentContext } from "../../Context/PaymentState";
import { creatorContext } from "../../Context/CreatorState";
import { CouponContext } from "../../Context/services/CouponState";

export const PricingCheckOut = () => {
  const { getPricingPlansInfo } = useContext(siteControlContext);
  const { SelectNewEventPlan, getEaseBuzz, VerifyNewEventPlan } =
    useContext(paymentContext);
  const { getAllCreatorInfo, allCreatorInfo, basicNav } =
    useContext(creatorContext);
  const navigate = useNavigate();
  const [Amount, SetAmount] = useState(0);

  const [GstIN, SETGstIN] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const [price, setPrice] = useState(null);
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [GST, SetGST] = useState(0);
  const [plan, SetPlan] = useState(null);
  const [subscriptionType, SetSubscriptionType] = useState(null);
  const planID = query?.get("planID");

  //Coupon
  const [openCoupon, setOpenCoupon] = useState(false);
  const [openGSTIN, setOpenGSTIN] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [appliedCoup, setAppliedCoup] = useState(false);
  const { checkAndVerifyCouponCode } = useContext(CouponContext);
  const [couponError, setCouponError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("jwtToken") && localStorage.getItem("c_id")) {
      getAllCreatorInfo();
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("jwtToken")]);

  useEffect(() => {
    async function getPlanInfo() {
      const result = await getPricingPlansInfo(planID);
      if (result) {
        setPrice(result?.psp.$numberDecimal);
        SetPlan(
          result?.name?.split(" ")[0] + " " + result?.name?.split(" ")[1]
        );
        SetSubscriptionType(result?.subscriptionType);
      } else {
        toast.error(result?.Error, { autoClose: 1500 });
      }
    }
    getPlanInfo();
  }, []);

  useEffect(() => {
    SetAmount(
      (
        parseFloat(appliedCoup ? discountedprice : price) +
        (parseFloat(appliedCoup ? discountedprice : price) * 18) / 100
      ).toFixed(2)
    );
    SetGST(
      ((parseFloat(appliedCoup ? discountedprice : price) * 18) / 100).toFixed(
        2
      )
    );
  }, [price, discountedprice, appliedCoup]);

  const handleChange = (e) => {
    SETGstIN(e.target.value);
  };

  const handleCouponCodeSubmit = async () => {
    const result = await checkAndVerifyCouponCode(
      coupon.toUpperCase(),
      price,
      planID
    );
    if (result?.success) {
      setAppliedCoup(true);
      setCouponError(null);
      setDiscountedPrice(result?.netToPay);
    } else {
      setCouponError(result?.error);
    }
  };

  // Handling the payment responses
  const handlePaymentResponse = async (response, orderId) => {
    setPaymentProcessing(true);
    setIsLoading(false);
    try {
      switch (response.status) {
        // 1. user cancelled the payment mode
        case "userCancelled":
          setPaymentProcessing(false);
          setIsLoading(false);
          toast.error("You cancelled the Order!", {
            position: "top-center",
            autoClose: 1000,
          });
          break;
        //2. payment dropping by user --- dropped the payment by the user
        case "dropped":
          setPaymentProcessing(false);
          setIsLoading(false);
          toast.error("You Dropped the Order!", {
            position: "top-center",
            autoClose: 1000,
          });
          break;
        //  2. payment failed due to any reasone
        case "failure":
          setPaymentProcessing(false);
          setIsLoading(false);
          toast.error(
            "Payment Failed, if amount got deducted inform us at info@anchors.in",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;
        //  3. Payment pending due to any reason
        case "pending":
          // Inform lark bot about the failure
          setPaymentProcessing(false);
          setIsLoading(false);
          toast.error(
            "Payment is still pending, complete the payment to proceed,for issues inform us at info@anchors.in",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;
        // 4. success payment
        case "success":
          setPaymentProcessing(false);
          setIsLoading(false);
          let result = await VerifyNewEventPlan({
            orderId,
            paymentData: response,
            planID,
          });
          if (result?.success) {
            toast.success("Order Placed Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            window.open(`/pricing/checkout/success/?plan=${plan}`, "_self");
          } else {
            throw new Error("Some error occured");
          }
          break;
        // Else all cases  -----------------
        default:
          setPaymentProcessing(false);
          setIsLoading(false);
          toast.error(
            "The order is not placed. Try again!!! ,in case of issues inform us at info@anchors.in ",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;
      }
    } catch (error) {
      toast.error(
        "The order is not placed. Try again!!! ,in case of issues inform us at info@anchors.in ",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
    }
  };

  const handlePaymentClick = async () => {
    if (localStorage.getItem("jwtToken")) {
      setPaymentProcessing(true);
      setIsLoading(true);
      try {
        if (Amount === 0 || !Amount) {
          setPaymentProcessing(false);
          setIsLoading(false);
          return toast.error("Please Enter Amount", {
            position: "top-center",
            autoClose: 1000,
          });
        }
        const order = await SelectNewEventPlan({
          productName: "SubscriptionPlanBought",
          amount: Amount,
          gst: GST,
          netToPay: price,
          userName: basicNav?.name,
          userEMail: basicNav?.email,
          subject: "Purchase Subscription",
          planID: planID,
          userPhone: allCreatorInfo?.phone ?? "8799710137",
          gstInNumber: GstIN,
          couponName: appliedCoup ? coupon : undefined,
          couponDiscount: appliedCoup
            ? parseFloat(price) - parseFloat(discountedprice)
            : 0,
        });
        if (Amount > 0) {
          const key = await getEaseBuzz();
          const easebuzzCheckout = new window.EasebuzzCheckout(key, "prod");
          const options = {
            access_key: order?.paymentData.data,
            onResponse: (response) => {
              // handling the edge cases of the response
              handlePaymentResponse(response, order?.order ?? "");
            },
            theme: "#000000", // color hex
          };
          easebuzzCheckout.initiatePayment(options);
        } else {
          window.open("/pricing", "_self");
        }
      } catch (error) {
        toast.error("Error in proccessing the payment", {
          position: "top-center",
          autoClose: 1000,
        });
        setPaymentProcessing(false);
        setIsLoading(false);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {isLoading && <LoadThree />}
      <div className="EventsPricingCheckoutPageOuterContainer">
        <EventsNavbar showPricingButton={false} />

        <div className="EventsPricingCheckoutPageContainer">
          <span className="EventsPricingCheckoutPageHeader">Checkout</span>
          <span className="EventsPricingCheckoutPageSubHeader">
            Double-check everything before continuing
          </span>
          <div className="EventsPricingCheckoutPageMainContainer">
            <span className="EventsPricingCheckoutPageMainContainerHeaderInfo">
              Subscribe to {subscriptionType} plan{" "}
            </span>
            <span className="EventsPricingCheckoutPageMainContainerPlanName">
              {plan}{" "}
            </span>
            <div className="EventsPricingCheckoutPageMainContainerPlanAmountcontainer">
              <div className="EventsPricingCheckoutPageMainContainerPlanAmountcontaineRow">
                <span>Validity</span>
                <span>
                  {subscriptionType === "yearly"
                    ? "12 Months"
                    : subscriptionType === "quarterly"
                    ? "3 Months"
                    : "1 Month"}
                </span>
              </div>
              <div className="EventsPricingCheckoutPageMainContainerPlanAmountcontaineRow">
                <span>Amount</span>
                <span>₹{price}</span>
              </div>
              <div className="EventsPricingCheckoutPageMainContainerPlanAmountcontaineRow">
                <span>GST</span>
                <span>₹{GST}</span>
              </div>
            </div>
            {/* Coupon input */}
            <div className="EventsPricingCheckoutPageMainContainerCouponContainer">
              <span>Do you have coupon code?</span>
              <div
                style={{
                  justifyContent: openCoupon ? "flex-end" : "flex-start",
                }}
                onClick={() => {
                  setOpenCoupon(!openCoupon);
                  setAppliedCoup(false);
                  setCouponError(null);
                }}
                className="EventsPricingCheckoutPageMainContainerCouponToggleButton"
              >
                <div
                  style={{ background: openCoupon ? "#ff5c5c" : "#757575" }}
                  className="EventsPricingCheckoutPageMainContainerCouponToggleButtonInside"
                ></div>
              </div>
            </div>
            {openCoupon && (
              <div className="EventsPricingCheckoutPageMainContainerApplyCouponContainer">
                <input
                  type="text"
                  placeholder="Enter Coupon Code"
                  style={{
                    border: "1px solid #424242",
                  }}
                  value={coupon}
                  onChange={(e) => {
                    setCoupon(e.target.value);
                    setAppliedCoup(false);
                  }}
                />
                <button disabled={appliedCoup} onClick={handleCouponCodeSubmit}>
                  {appliedCoup ? "Applied" : "Apply"}
                </button>
              </div>
            )}
            {couponError && (
              <span className="EventsPricingCheckoutPageMainContainerApplyCouponError">
                {couponError}
              </span>
            )}
            {appliedCoup && (
              <span className="EventsPricingCheckoutPageMainContainerApplyCouponSuccess">
                Successfully Applied got ₹
                {(parseFloat(price) - parseFloat(discountedprice)).toFixed(2)}{" "}
                Off
              </span>
            )}
            {/* GSTIN input */}
            <div className="EventsPricingCheckoutPageMainContainerCouponContainer">
              <span>Do you have GSTIN number?</span>
              <div
                style={{
                  justifyContent: openGSTIN ? "flex-end" : "flex-start",
                }}
                onClick={() => {
                  setOpenGSTIN(!openGSTIN);
                }}
                className="EventsPricingCheckoutPageMainContainerCouponToggleButton"
              >
                <div
                  style={{ background: openGSTIN ? "#ff5c5c" : "#757575" }}
                  className="EventsPricingCheckoutPageMainContainerCouponToggleButtonInside"
                ></div>
              </div>
            </div>
            {openGSTIN && (
              <div className="EventsPricingCheckoutPageMainContainerApplyCouponContainer">
                <input
                  type="text"
                  placeholder="Enter GSTIN number"
                  style={{
                    border: "1px solid #424242",
                  }}
                  value={GstIN}
                  onChange={(e) => {
                    SETGstIN(e.target.value);
                  }}
                />
              </div>
            )}
            <div className="EventsPricingCheckoutPageMainContainerSeperator">
              --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            </div>
            <div className="EventsPricingCheckoutPageMainContainerFooter">
              {appliedCoup && (
                <div className="EventsPricingCheckoutPageMainContainerPlanAppliedCouponRow">
                  <span>Grand Total</span>
                  <span>
                    ₹{(parseFloat(price) + parseFloat(GST)).toFixed(2)}
                  </span>
                </div>
              )}
              {appliedCoup && (
                <div className="EventsPricingCheckoutPageMainContainerPlanAppliedCouponRow">
                  <span>Coupon Discount</span>
                  <span style={{ color: "#10B981" }}>
                    ₹
                    {(parseFloat(price) - parseFloat(discountedprice)).toFixed(
                      2
                    )}
                  </span>
                </div>
              )}
              <div className="EventsPricingCheckoutPageMainContainerPlanSubTotalRow">
                <span>Sub Total</span>
                <span>₹{Amount}</span>
              </div>
            </div>
            <Button4
              text={"CONTINUE TO PAY"}
              onClick={() => handlePaymentClick()}
              icon={<GoArrowRight />}
              rightIcon={undefined}
              background={undefined}
              textColor={undefined}
              borderColor={undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
};
