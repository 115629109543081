import React from "react";
import Navbar from "../../Components/Layouts/Navbar Creator/Navbar";
import { useState } from "react";
import { host } from "../../config/config";
import { toast } from "react-toastify";

export const CreditsTopup = () => {
  const [data, setData] = useState({
    email: "",
    reason: "",
    credits: "",
  });
  const [showPreview, SetShowPreview] = useState(false);
  const [creator, SetCreator] = useState();
  const [creatorPlan, SetCreatorPlan] = useState();

  const handleChange = (e) => {
    console.log(e.target.name);
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  console.log(data);
  const handlePreview = async () => {
    if (
      !data?.email ||
      !data.reason ||
      !data.credits ||
      data?.email === "" ||
      data?.reason === "" ||
      data?.credits === ""
    ) {
      alert("please Enter all the fields");
      //   return toast.error("Please Enter all the fields", { autoClose: 1500 });
      return;
    } else {
      const response = await fetch(
        `${host}/api/developer/getEventCreatorByEmail/${data?.email}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": true,
            "jwt-token": localStorage.getItem("jwtTokenD"),
          },
        }
      );
      const json = await response.json();
      if (json.success) {
        SetShowPreview(true);
        SetCreator(json?.creator);
        SetCreatorPlan(json?.planActivated);
      } else {
        alert(json.error);
      }
    }
  };
  const updateHost = async () => {
    try {
      const response = await fetch(`${host}/api/developer/updateEventCreator`, {
        method: "POST",
        body: JSON.stringify({
          data,
          expireAt: creatorPlan?.expireAt,
        }),
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true", // Ensure this is a string
          "jwt-token": localStorage.getItem("jwtTokenD"),
        },
      });
      const json = await response.json();
      if (json.success) {
        alert("Credits Added Successfully");
      } else {
        alert(json.error);
      }
    } catch (error) {
      console.error("Error while updating host:", error);
    }
  };

  return (
    <>
      <Navbar noAccount={true} />
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <span style={{ font: "inter", fontSize: "20px", fontWeight: "700" }}>
          Recharge Credits of a Host
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
            border: "1px solid black",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label style={{ cursor: "pointer" }} htmlFor="email">
                Enter Email
              </label>
              <input
                style={{ padding: "12px 20px", borderRadius: "8px" }}
                type="email"
                id="email"
                value={data?.email}
                name="email"
                placeholder="yash.kumaryadav.1610@gmail.com"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label style={{ cursor: "pointer" }} htmlFor="reason">
                Select Reason
              </label>
              <select
                style={{ padding: "12px 20px", borderRadius: "8px" }}
                id="reason"
                value={data.reason}
                name="reason"
                onChange={handleChange}
                placeholder="Select a reason"
              >
                <option value=""></option>
                <option value="Recharge Credits by Admin">
                  Recharge Credits by Admin
                </option>
                {/* Add more options as needed, also add them in Corder model */}
              </select>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label style={{ cursor: "pointer" }} htmlFor="credits">
                Enter Credits
              </label>
              <input
                style={{ padding: "12px 20px", borderRadius: "8px" }}
                type="number"
                id="credits"
                value={data?.credits}
                placeholder="10"
                name="credits"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={handlePreview}
              style={{ padding: "12px 20px", borderRadius: "8px" }}
            >
              PREVIEW
            </button>
          </div>
        </div>
        {showPreview && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
              border: "1px solid black",
            }}
          >
            <span
              style={{
                display: "flex",
                font: "inter",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Host Details
            </span>
            <span>{`Name : ${creator?.name}`}</span>
            <span>{`Email : ${creator?.email}`}</span>
            <span>{`Event Status: ${creator?.eventStatus}`}</span>
            <span>{`ActivePlan : ${creatorPlan?.planID?.name}`}</span>
            <span>{`Plan Expiry: ${new Date(creatorPlan?.expireAt)}`}</span>
            <span>{`Allowed Events: ${creatorPlan?.planID?.allowedEvents}`}</span>
            <span>{`Current Total Availaible Credits: ${creatorPlan?.usageID?.totalAvailableCredits}`}</span>
            <span>{`New Total Availaible Credits: ${
              parseInt(creatorPlan?.usageID?.totalAvailableCredits) +
              parseInt(data?.credits)
            }`}</span>
            <span>{`Total Availaible Events: ${creatorPlan?.usageID?.totalAvailableEvents}`}</span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={updateHost}
                style={{ padding: "12px 20px", borderRadius: "8px" }}
              >
                Confirm To Proceed
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
