import React, { createContext, useContext } from "react";
import axios from "axios";
import { host } from "../../config/config";

export const CouponContext = createContext();

const CouponState = (props) => {
  // route-1 . Check Coupon Code ---------------------
  const checkAndVerifyCouponCode = async (couponCode, amount, planID) => {
    const result = await fetch(
      `${host}/api/coupons/checkAndVerify?code=${couponCode}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "jwt-token": localStorage.getItem("jwtToken"),
        },
        body: JSON.stringify({
          planID: planID,
          amount: amount,
        }),
      }
    );

    return result.json();
  };

  return (
    <CouponContext.Provider value={{ checkAndVerifyCouponCode }}>
      {props?.children}
    </CouponContext.Provider>
  );
};
export default CouponState;
