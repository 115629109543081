import React, { useState, useRef, useContext, useEffect } from "react";
import Image1 from "./utils/image1";
import Image2 from "./utils/image2";
import Image3 from "./utils/image3";
import Image4 from "./utils/image4";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { TbCircleXFilled } from "react-icons/tb";

import "./NewPricing.css";
import { EventsNavbar } from "../Layouts/Navbar Creator/Navbar";
import MainNewFooter from "../Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import { SuperSEO } from "react-super-seo";
import { siteControlContext } from "../../Context/SiteControlsState";
import { paymentContext } from "../../Context/PaymentState";
import { creatorContext } from "../../Context/CreatorState";
import { LoadThree } from "../Modals/Loading";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const StartupPlanPoints = (maxevents, userCredits, extraCharges, eventType) => {
  return [
    `Host ${eventType === "paid" ? "Free/Paid" : "Free"} Events only`,
    `Host upto ${maxevents} Events`,
    `*${userCredits} free credits for up to ${userCredits} attendees`,
    `Transactional/Reminder WhatsApps`,
    `Transactional/Reminder Emails`,
    `Automated Banner & Marketing Graphics`,
    `Inbuilt Referral System for Attendees`,
    `Post-event Certificates for Attendees`,
    `Downloadable User Data`,
    `Detailed Analysis`,
    `Custom Brand Page`,
  ];
};

const FreeTrialPlanPoints = (maxevents, userCredits, extraCharges) => {
  return [
    `Host Free & Paid Events`,
    `Host up to ${maxevents} events`,
    `${userCredits} free credits for up to 15 attendees`,
    `Transactional/Reminder WhatsApps`,
    `Transactional/Reminder Emails`,
    `Automated Banner & Marketing Graphics`,
    `Inbuilt Referral System for Attendees`,
    `Post-event Certificates for Attendees`,
    `Downloadable User Data`,
    `Detailed Analysis`,
    `Custom Brand Page`,
  ];
};

const PremiumPlanPoints = (maxevents, userCredits, eventType) => {
  return [
    `Host Free & Paid Events`,
    `${maxevents === "unlimited" ? "Unlimited" : maxevents} Events`,
    `${
      userCredits === "unlimited" ? "Unlimited" : userCredits
    } User Registration`,
    `Transactional/Reminder WhatsApps`,
    `Transactional/Reminder Emails`,
    `Automated Banner & Marketing Graphics`,
    `Inbuilt Referral System for Attendees`,
    `Post-event Certificates for Attendees`,
    `Downloadable User Data`,
    `Detailed Analysis`,
    `Custom Brand Page`,
    `Domestic & International Payments`,
    `Abandoned Cart Funnel`,
    `Recommendation to Increase
    Reach and Registrations`,
  ];
};

const ComparePoints = (maxEvents, userCredits, extraCharges) => {
  return [
    {
      point: "Host Free Event",
      starter: true,
      premium: true,
    },
    {
      point: "Host Paid Event",
      starter: false,
      premium: true,
    },
    {
      point: "Brand Color Page",
      starter: true,
      premium: true,
    },
    {
      point: "WhatsApp Transactional/Reminder",
      starter: true,
      premium: true,
    },
    {
      point: "Email Transactional/reminder",
      starter: true,
      premium: true,
    },
    {
      point: "Certificate for attendee",
      starter: true,
      premium: true,
    },
    {
      point: "Automated Marketing Graphics",
      starter: true,
      premium: true,
    },
    {
      point: "Inbuilt Referral System for attendee",
      starter: true,
      premium: true,
    },
    {
      point: "Detailed Analysis",
      starter: true,
      premium: true,
    },
    {
      point: "Number of Events",
      starterText: `Upto ${maxEvents} Events`,
      premiumText: "Unlimited",
    },
    {
      point: "Number of users Can Register",
      starterText: `Up to ${userCredits} users + ${extraCharges} Rupees/user`,
      premiumText: "Unlimited",
    },
    {
      point: "Domestic / International Payment",
      starter: false,
      premium: true,
    },
    {
      point: "Abandoned Cart Funnel",
      starter: false,
      premium: true,
    },
    {
      point: "Recommendation for new page if low conversion rate",
      starter: false,
      premium: true,
    },
  ];
};

const FreeTrialNegativePoints = () => {
  return [
    `Domestic & International Payments`,
    `Abandoned Cart Funnel`,
    `Recommendation to Increase
    Reach and Registrations`,
  ];
};

const StartupPlanNegativePoints = () => {
  return [
    `Domestic & International Payments`,
    `Abandoned Cart Funnel`,
    `Recommendation to Increase
    Reach and Registrations`,
  ];
};

const PricingBox = ({
  selectedChart,
  setSelectedChart,
  plandata,
  customCard,
  onClick,
}) => {
  return (
    <div
      className={`chart_container ${
        selectedChart === plandata?._id ? "selected" : ""
      }`}
    >
      <div className={`chart`} onClick={() => setSelectedChart(plandata?._id)}>
        <div className="heading">
          {plandata?.name?.includes("Starter")
            ? "Starter Plan"
            : plandata?.name?.includes("Premium")
            ? "Premium Plan"
            : "Free Trial"}
        </div>
        <div className="content">
          <div className="price">
            {customCard ? (
              <h1 style={{ fontSize: "20px" }}>NO CREDIT CARD REQUIRED</h1>
            ) : (
              <>
                <h1>₹{plandata?.psp?.$numberDecimal}</h1>
                <h2 className="strikethrough">
                  {plandata?.pmrp?.$numberDecimal}
                </h2>
              </>
            )}
          </div>
          <div className="per_month">
            {customCard ? (
              ""
            ) : (
              <>
                per{" "}
                {plandata?.subscriptionType === "monthly"
                  ? "Month"
                  : plandata?.subscriptionType === "quarterly"
                  ? "Quarter"
                  : "Year"}
                {plandata?.anchorsCharges &&
                  ` + ${
                    plandata?.anchorsCharges?.$numberDecimal * 100 + "%"
                  } of revenue`}
              </>
            )}
          </div>
          <button
            style={{ display: "none" }}
            onClick={() => onClick(plandata?._id)}
          >
            {selectedChart === plandata?._id ? <Image3 /> : <Image1 />}
            Get Started
          </button>

          <div className="list_of_feature">
            {/* {plandata?.name?.includes("Premium") && (
              <p>Everything in Starter Plan +</p>
            )} */}
            {/* Positive Points */}
            {plandata?.name?.includes("Starter")
              ? StartupPlanPoints(
                  plandata?.maxEvents,
                  plandata?.userCredits,
                  plandata?.extraChargesForCredits?.$numberDecimal,
                  plandata?.allowedEvents
                ).map((e, i) => {
                  return (
                    <div className="feature" key={`starter${i}`}>
                      <FaCheckCircle color="#10B981" size={20} /> {e}
                    </div>
                  );
                })
              : plandata?.name?.includes("Premium")
              ? PremiumPlanPoints(
                  plandata?.maxEvents,
                  plandata?.userCredits,
                  plandata?.extraChargesForCredits?.$numberDecimal
                ).map((e, i) => {
                  return (
                    <div className="feature" key={`starter${i}`}>
                      <FaCheckCircle color="#10B981" size={20} /> {e}
                    </div>
                  );
                })
              : FreeTrialPlanPoints(
                  plandata?.maxEvents,
                  plandata?.userCredits,
                  plandata?.extraChargesForCredits?.$numberDecimal
                ).map((e, i) => {
                  return (
                    <div className="feature" key={`starter${i}`}>
                      <FaCheckCircle color="#10B981" size={20} /> {e}
                    </div>
                  );
                })}

            {/* negative points */}
            {plandata?.name?.includes("Starter")
              ? StartupPlanNegativePoints().map((e, i) => {
                  return (
                    <div
                      // style={{ textDecoration: "line-through" }}
                      className="feature"
                      key={`starter${i}`}
                    >
                      <TbCircleXFilled color="#FF8A8A" size={20} /> {e}
                    </div>
                  );
                })
              : plandata?.name?.includes("Free")
              ? FreeTrialNegativePoints().map((e, i) => {
                  return (
                    <div
                      // style={{ textDecoration: "line-through" }}
                      className="feature"
                      key={`starter${i}`}
                    >
                      <TbCircleXFilled color="#FF8A8A" size={20} /> {e}
                    </div>
                  );
                })
              : null}
            {plandata?.name?.includes("Starter") ? (
              <div className="feature">{`*Additional credits available at Rs. ${plandata?.extraChargesForCredits?.$numberDecimal} per credit`}</div>
            ) : plandata?.name?.includes("Free") ? (
              <div className="feature">{`*Additional credits available at Rs. ${plandata?.extraChargesForCredits?.$numberDecimal} per credit`}</div>
            ) : null}
          </div>
          <button
            onClick={() => onClick(plandata?._id)}
            className="host_button"
            style={{ width: "90%" }}
          >
            {selectedChart === plandata?._id ? <Image3 /> : <Image1 />}
            {plandata?.name?.includes("Free") ? "Start Free Trial" : "Select Plan"}
          </button>
        </div>
      </div>
    </div>
  );
};

const NewPricing = () => {
  const {
    getAllPricingPlans,
    pricingPlans,
    getTrialPricingPlan,
    TrialpricingPlans,
  } = useContext(siteControlContext);
  const { getAllCreatorInfo, allCreatorInfo, basicNav } =
    useContext(creatorContext);

  const [selectedChart, setSelectedChart] = useState("Premium");
  const [selectedTime, setSelectedTime] = useState("monthly");

  const [showPlanArray, setShowPlanArray] = useState([]);
  const { SelectNewEventPlan } = useContext(paymentContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("jwtToken") && localStorage.getItem("c_id")) {
      setIsLoading(true);
      getAllCreatorInfo();
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("jwtToken")]);

  const handleTimeClick = (Time) => {
    setSelectedTime(Time);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllPricingPlans();
    getTrialPricingPlan();
    mixpanel.track("Page Visit");
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let arr = pricingPlans?.filter((e) => {
      return e?.subscriptionType === selectedTime;
    });
    arr?.sort(
      (a, b) =>
        b.extraChargesForCredits.$numberDecimal -
        a.extraChargesForCredits.$numberDecimal
    );
    setShowPlanArray(arr);
    setSelectedChart(arr?.[0]?._id);
  }, [selectedTime, pricingPlans]);

  const handleSelectFreeTrialPlan = async () => {
    if (localStorage.getItem("jwtToken")) {
      setIsLoading(true);

      let result = await SelectNewEventPlan({
        productName: "SubscriptionPlanBought",
        amount: 0,
        gst: 0,
        netToPay: 0,
        userName: basicNav?.name,
        userEMail: basicNav?.email,
        subject: "Purchase Subscription",
        planID: TrialpricingPlans?._id,
        userPhone: allCreatorInfo?.phone ?? "8799710137",
      });

      if (result.success) {
        toast.success("Plan Activated Successfully", {
          position: "top-center",
          autoClose: 1000,
        });

        setTimeout(() => {
          setIsLoading(false);
          window.open(`/pricing/checkout/success/?plan="Free Trial"`, "_self");
        }, 1000);
      } else {
        setIsLoading(false);
        toast.error(result.error, {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } else {
      navigate("/login");
    }
  };

  const handleSelect = (planID) => {
    if (localStorage.getItem("jwtToken")) {
      navigate(`/pricingCheckout/?planID=${planID}`);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {isLoading && <LoadThree />}

      <div className="Billing_page">
        <EventsNavbar showPricingButton={false} position="absolute" />
        <div className="Billing">
          <div style={{ display: "flex", gap: "15px" }}>
            <h1>Our Pricing</h1>
            <h1 className="is_simple">is Simple</h1>
          </div>
          <div className="sub_heading">
            {/* <h3>
              No Setup & Monthly Cost waived off till 31st March We only earn if
              you earn
            </h3> */}
            <div className="time_buttons">
              <button
                className={`${selectedTime === "monthly" ? "selected" : ""}`}
                onClick={() => {
                  handleTimeClick("monthly");
                  mixpanel.track("monthly");
                }}
              >
                Monthly
              </button>
              <button
                className={`${selectedTime === "quarterly" ? "selected" : ""}`}
                onClick={() => {
                  handleTimeClick("quarterly");
                  mixpanel.track("quarterly");
                }}
              >
                Quarterly
              </button>
              <button
                className={`${selectedTime === "yearly" ? "selected" : ""}`}
                onClick={() => {
                  handleTimeClick("yearly");
                  mixpanel.track("yearly");
                }}
              >
                Yearly
              </button>
            </div>
          </div>
          <div class="square"></div>

          <div className="price_chart">
            <PricingBox
              plandata={TrialpricingPlans}
              selectedChart={selectedChart}
              setSelectedChart={setSelectedChart}
              onClick={handleSelectFreeTrialPlan}
              customCard={true}
            />
            ;
            {showPlanArray?.map((plan) => {
              return (
                <PricingBox
                  plandata={plan}
                  selectedChart={selectedChart}
                  setSelectedChart={setSelectedChart}
                  onClick={(id) => handleSelect(id)}
                />
              );
            })}
            {/* <PricingBox
              customCard={true}
              selectedChart={selectedChart}
              setSelectedChart={setSelectedChart}
              plandata={{ _id: "custom" }}
            /> */}
          </div>
          <span className="pricingpaymentGatewayInfo">
            <span>
              In Pricing : Payment Gateway charges are excluded ( 0.1%-2.3%)
            </span>
          </span>
          {/* old UI Display is none */}
          <div className="table-container">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>
                    <h1>Compare plans</h1>
                    <h3>Find one that’s right for you</h3>
                  </th>
                  <th>
                    <h1>Starter Plan</h1>
                    {/* <button onClick={()=>{handleSelectFreeTrialPlan("Free Trial")}}> */}
                    <button
                      onClick={() => {
                        handleSelectFreeTrialPlan();
                      }}
                    >
                      <Image4 /> Start Free Trial
                    </button>
                  </th>
                  <th>
                    <h1>Premium Plan</h1>
                    <button
                      onClick={() => {
                        handleSelectFreeTrialPlan();
                      }}
                    >
                      <Image4 /> Start Free Trial
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- Rows --> */}
                {showPlanArray &&
                  ComparePoints(
                    showPlanArray?.filter((item) =>
                      item?.name?.includes("Starter")
                    )[0]?.maxEvents,
                    showPlanArray?.filter((item) =>
                      item?.name?.includes("Starter")
                    )[0]?.userCredits,
                    showPlanArray?.filter((item) =>
                      item?.name?.includes("Starter")
                    )[0]?.extraChargesForCredits?.$numberDecimal
                  )?.map((e, key) => {
                    return (
                      <tr key={`row${key}`}>
                        <td>
                          <div style={{ marginLeft: "20px" }}>{e?.point}</div>
                        </td>
                        <td>
                          {e?.starterText ?? (e?.starter ? <Image2 /> : "-")}
                        </td>
                        <td>
                          {e?.premiumText ?? (e?.premium ? <Image2 /> : "-")}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {/* old UI Display is none */}
          <div className="pricingFreeTrialContainer">
            <span className="pricingFreeTrialContainerHeader">
              Try before committing
            </span>
            <span className="pricingFreeTrialContainerSubHeader">
              10-day free trial
            </span>
            <div className="pricingFreeTrialContainerContent">
              <div
                style={{ height: "auto", margin: 0 }}
                className="list_of_feature"
              >
                {FreeTrialPlanPoints(2, 15, 5).map((e, i) => {
                  return (
                    <div
                      style={{ margin: 0 }}
                      className="feature"
                      key={`starter${i}`}
                    >
                      <Image2 /> {e}
                    </div>
                  );
                })}
              </div>
              <div className="pricingFreeTrialContainerContentleft">
                <button
                  onClick={() => {
                    handleSelectFreeTrialPlan();
                  }}
                  className="pricingFreeTrialContainerContentButton"
                  // style={{ width: "90%" }}
                >
                  {<Image3 />}
                  START FREE TRIAL
                  <FaArrowRightLong />
                </button>

                <span>NO CRedit card required</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainNewFooter
        onEvents={true}
        footerOptions1={[
          {
            title: "Event Pricing",
            link: "https://www.anchors.in/eventpricing",
          },
          {
            title: "Sample Event Page",
            link: "https://www.anchors.in/e/how-to-become-a-product-manager",
          },
          {
            title: "Sample Referral Page",
            link: "https://www.anchors.in/static/success",
          },
        ]}
        noPrivacyPolicy={false}
        noRefund={false}
        useEventsLogo={true}
      />
      <ToastContainer />
      {/* <SuperSEO title="Anchors - Pricing" /> */}
    </>
  );
};
export default NewPricing;
