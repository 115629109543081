import { useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { EventsNavbar } from "../Layouts/Navbar Creator/Navbar";
import Confetti from "react-confetti";
import { Button2 } from "../Editor/New UI/Create Services/InputComponents/buttons";

export const CheckOutSuccess = ({ showNavbar = true, Header, onClick }) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const plan = query?.get("plan");
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showNavbar && (
        <EventsNavbar showPricingButton={false} position="absolute" />
      )}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200} // Adjust the number of confetti pieces
          gravity={0.1} // Adjust the gravity for confetti falling speed
          initialVelocityX={{ min: -5, max: 5 }} // Adjust initial horizontal velocity
          initialVelocityY={{ min: 10, max: 20 }} // Adjust initial vertical velocity
          colors={["#FFD700", "#FFA500", "#FF6347", "#FF4500"]} // Use colors resembling fireworks
          recycle={false}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          marginTop: "137px",
          alignItems: "center",
          justifyContent: "center",
          color: "#424242",
        }}
        className="text-[#424242] flex flex-col w-full h-full mt-[137px] items-center justify-center"
      >
        <img
          style={{ marginBottom: "30px" }}
          className="mb-[30px]"
          src="/tick_circle.svg"
          alt=""
        />
        <p
          style={{ fontWeight: "700", fontSize: "40px", textAlign: "center" }}
          className="text-[40px] font-bold text-[#424242]"
        >
          {Header ?? `${plan || "No Plan"} Plan Activated!`}
        </p>
        <p
          style={{ textAlign: "center", fontSize: "14px", fontWeight: "400" }}
          className="text-center text-[14px] font-[400]"
        >
          Get ready to transform your business with influencer power. <br />
          Let's go!
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "40px 20px",
            marginTop: "20px",
            alignItems: "center",
            gap: "20px",
            borderRadius: "8px",
          }}
          className="flex flex-col px-[40px] py-[20px] mt-[20px] items-center gap-[20px] rounded-[8px]"
        >
          <Button2
            onClick={() => (onClick ? onClick() : navigate("/dashBoard"))}
            text={`Go To DashBoard!`}
            icon={undefined}
            rightIcon={<FaArrowRightLong />}
            background={undefined}
            textColor={undefined}
            borderColor={undefined}
          />
        </div>
      </div>
    </>
  );
};
