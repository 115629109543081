export const host = "https://www.anchors.in:5000";
// export const host = "http://localhost:5000";

// original mix panel token
export const mixPanelToken = "0d29c69a5d10ed4e0dc9c06fdf79496b";

// mix panel for testing
// export const mixPanelToken = "a51c0631ddfcb2e0791b693af3b534d1";

// export const mixPanelToken = "a51c0631ddfcb2e0791b693af3b534d1"

export const jwtTokenDeveloper =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjM3MGUwMmZlOWRmNjBjNTY5OGIwMGQzIn0sImlhdCI6MTY3MDI2NDMwNX0.s9J2ny1S3Pz47R8DC058kYZW-RZxXj7rsYHPzzALp6M";

export const constantString = {
  anchorsMainUrl: "http://www.anchors.in",
};
