import React, { useEffect, useState } from "react";
import "./Sample.css";
import "./Event.css";
import { motion } from "framer-motion";

import Banner1 from "./images/Banner1.webp";
import Banner2 from "./images/Banner2.webp";
import Banner3 from "./images/Banner3.webp";
import Banner4 from "./images/Banner4.webp";
import Banner5 from "./images/Banner5.webp";
import mixpanel from "mixpanel-browser";
import {
  TextField1,
  DatePicker1,
} from "../Create Services/InputComponents/fields_Labels";
import { EventsNavbar } from "../../../Layouts/Navbar Creator/Navbar";
import { ToastContainer } from "react-toastify";
import { RiEmotionHappyLine } from "react-icons/ri";
import NewPricing from "../../../Pricing/New Pricing";
import { IoMdTime } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Button4 } from "../Create Services/InputComponents/buttons";
import { FaArrowRight } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";
import {
  EventTestimonial,
  testimonialData,
} from "./Testimonial/EventTestimonial";

const PersonalizedSection = () => {
  const containerVariant = (index) => {
    let yoffset;
    let xoffset;
    let time;

    switch (index) {
      case 0:
        yoffset = 0;
        xoffset = window.screen.width > 600 ? 140 : 120;
        time = 0.2;
        break;
      case 1:
        yoffset = -40;
        xoffset = window.screen.width > 600 ? 70 : 44;
        time = 0.4;
        break;
      case 2:
        yoffset = -80;
        xoffset = 0;
        time = 0.6;
        break;
      case 3:
        yoffset = -40;
        xoffset = window.screen.width > 600 ? -70 : -44;
        time = 0.4;
        break;
      case 4:
        yoffset = 0;
        xoffset = window.screen.width > 600 ? -140 : -120;
        time = 0.2;
        break;
    }

    return {
      from: {
        opacity: 0,
        y: "300px",
      },
      to: {
        opacity: 1,
        y: yoffset + "px",
        x: xoffset + "px",
        transition: {
          duration: time * 2,
          ease: "easeInOut",
        },
      },
    };
  };

  const imgSrc = [Banner1, Banner2, Banner3, Banner4, Banner5];

  return (
    <section className="personalized_section_sample_page">
      <div>
        {imgSrc?.map((e, i) => {
          return (
            <motion.img
              variants={containerVariant(i)}
              initial="from" // here default type is tween and not spring because it has duration
              whileInView="to"
              viewport={{ once: true }}
              src={e}
              alt=""
              key={i}
            />
          );
        })}
      </div>

      <section>
        <h2 className="header_sample_page01" style={{ textAlign: "center" }}>
          Personalized Invite Cards
        </h2>
        <p className="header_sample_page02" style={{ textAlign: "center" }}>
          Elevate Social Currency with Shareable, Customised Invites!
        </p>
      </section>
      {/* <button
        className="button_sample_page01"
        onClick={() => {
          window.open("https://www.anchors.in/static/success");
          mixpanel.track("Event Page Explore a Sample 3");
        }}
      >
        <TbMapSearch size={32} /> Explore a Sample
      </button> */}
    </section>
  );
};
const title = "anchors - Home for Creators | Monetize Content, Skills & Time";
const description =
  "anchors is the premium community platform exclusively for creators who are looking to take their content and careers to the next level. Our platform offers a range of tools and features to help creators manage their content, engage with their audience, and monetize their creations. Whether you're a social media influencer, blogger, vlogger, or podcaster, anchors provide an exclusive and supportive community that helps you grow and unlock the full potential of the creator's economy. Join our community of creators and start monetizing your content, skills, and time today!";
const imgUrl = "https://www.anchors.in:5000/api/file/anchors-logo.jpeg";
const keywords =
  "Join Anchors for creators,Exclusive community for creators,Monetize your content on Anchors,Creator economy platform,Connect with fellow creators on Anchors,Manage and monetize your content on Anchors,Unlock your potential on Anchors,Anchors for social media influencers, bloggers, vloggers, and podcasters,Collaborate with creators on Anchors,Join the Anchors community and grow your career as a creator";

function Sample() {
  const [openLoginModalFromOutside, setOpenLoginModalFromOutside] =
    useState(false);
  const [SelectedSolution, SetSelectedSolution] = useState(0);
  const navigate = useNavigate();

  const [data, setdata] = useState({
    sname: "",
    date: "",
    hname: "",
    startTime: "",
    endTime: "",
  });
  const getDate = (date) => {
    let d = new Date(date);

    let newDate = d.toDateString().split(" ");

    return (
      // newDate[0] + " | " + newDate[1] + " " + newDate[2] + " " + newDate[3]
      newDate[2] + " " + newDate[1] + " " + newDate[3]
    );
  };

  const convertTime = (inputTime) => {
    if (inputTime) {
      var timeParts = inputTime?.split(":");
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);

      var period = hours >= 12 ? "PM" : "AM";
      hours = hours > 12 ? hours - 12 : hours;

      var convertedTime =
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0") +
        " " +
        period;

      return convertedTime;
    }
  };

  useEffect(() => {
    mixpanel.track("Event Page Page Visit");
  }, []);

  const handleChange = (e) => {
    setdata({ ...data, [e?.target?.name]: e?.target?.value });
  };

  const imgVariant = (scale = 1) => {
    return {
      from: {
        opacity: 0,
        scale: 0.8,
      },
      to: {
        opacity: 1,
        scale: scale,
        transition: {
          duration: 2,
        },
      },
    };
  };

  const textVariant01 = (timedelay = 0.85) => {
    return {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
        transition: {
          duration: timedelay,
          // ease: "ease",
        },
      },
    };
  };

  const solutionButtons = [
    {
      item: "One-Click Magic",
      header: "Auto-Designed Banners & Marketing Graphics",
      footer:
        "Auto-design stunning banners & graphics for promoting on Instagram, LinkedIn & Whatsapp, in their aspect ratios.",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection1.png",
    },
    {
      item: "VIP Treatment",
      header: "Personalized Attendee Cards",
      footer:
        "Impress attendees with custom attendee cards featuring their photo & yours. Spark social sharing and excitement.",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection2.png",
    },
    {
      item: "Instant recognition",
      header: "Certificates of Participation",
      footer:
        "Celebrate achievements & keep attendees coming back. Send beautiful certificates with a single click.",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection3.png",
    },
    {
      item: "Flawless First Look",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection4.png",
    },
    {
      item: "Updates on-the-go",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection5.png",
    },
    {
      item: "Track Your Success",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection6.png",
    },
    {
      item: "Go Global",
      image:
        "https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionSection7.png",
    },
  ];

  return (
    <div className="sample_page_wrapper">
      {/* Hero Section  */}

      <EventsNavbar
        backgroundDark={true}
        openLoginModalValue={openLoginModalFromOutside}
        setOpenLoginModalFromOutside={setOpenLoginModalFromOutside}
      />
      <div className="sample_page_hero_Section">
        <div className="sample_page_hero_Section_left_section">
          <span className="sample_page_hero_Section_left_section_main_text">
            <span style={{ color: "#FF5C5C" }}>Event</span> <br /> Hosting{" "}
            <br /> Simplied!
          </span>
          <span className="sample_page_hero_Section_left_section_sub_text">
            Let us do the heavy-lifting for you
          </span>
          <button
            onClick={() => {
              mixpanel.track(
                `${
                  localStorage.getItem("jwtToken")
                    ? "Event Side My Account"
                    : "Event Side Clicked Start Your Free Trial on Hero Section"
                }`
              );
              !localStorage.getItem("jwtToken")
                ? setOpenLoginModalFromOutside(true)
                : navigate("/dashboard");
            }}
          >
            Start Your Free Trial
          </button>
        </div>
        {window.screen.width > 600 && (
          <div className="sample_page_hero_Section_right_section">
            <video
              id="video"
              className="rounded-[10px]"
              width="100%"
              loop={true}
              autoPlay={true}
              muted
              src="https://assets.softr-files.com/applications/3c246759-6eec-4dfb-95cf-8df391e622d0/assets/0c673c6f-759a-46bf-a31b-891509f5019f.mp4"
            ></video>
          </div>
        )}
      </div>

      {/* second section {your event} */}
      <div className="sample_page_your_event_Section">
        <div className="sample_page_hero_Section_left_section">
          {window.screen.width > 600 && (
            <span className="sample_page_hero_Section_left_section_main_text  sample_page_your_event_Section_main_text">
              Are you doing <br /> <span>a lot of</span> <br /> background{" "}
              <br /> <span> work?</span>
            </span>
          )}
          {window.screen.width < 600 && (
            <span className="sample_page_hero_Section_left_section_main_text  sample_page_your_event_Section_main_text">
              Are you doing
              <span> a lot of</span> <br /> background <span> work?</span>
            </span>
          )}
          <span className="sample_page_hero_Section_left_section_sub_text">
            For designing, listing, tracking, reminders, feedback,
            certificates...
          </span>
        </div>
        <div className="sample_page_your_event_Section_right_section">
          <img
            style={{ width: "100%" }}
            src="https://anchors-assets.s3.ap-south-1.amazonaws.com/SolutionCircle.png"
          />
        </div>
      </div>

      {/* third section solution */}
      <div className="sample_page_solution_Section">
        <div className="sample_page_solution_Section_solution_container">
          {" "}
          <RiEmotionHappyLine /> Solution
        </div>
        <span className="sample_page_solution_Section_main_text">
          <span style={{ color: "#E2E8F0" }}>We Make</span> Things Easier
        </span>
        <div className="sample_page_solution_Section_main_contianer">
          <div className="sample_page_solution_Section_main_contianer_left_section">
            <span className="sample_page_solution_Section_main_contianer_left_section_header">
              No more logistics & mundane tasks. 30 seconds is all it takes!
            </span>
            <div className="sample_page_solution_Section_main_contianer_left_section_button-container">
              {solutionButtons?.map((item, index) => (
                <div
                  onClick={() => {
                    mixpanel.track(`Clicked Solution ${index + 1} - ${item?.item}`);
                    SetSelectedSolution(index)
                  }}
                  className={`sample_page_solution_Section_main_contianer_left_section_button ${
                    SelectedSolution === index ? "Selected" : ""
                  }`}
                  key={index + 1}
                >
                  {item?.item}
                </div>
              ))}
            </div>
          </div>
          <div className="sample_page_solution_Section_main_contianer_right_section">
            {/* <div className="sample_page_solution_Section_main_contianer_right_section_header">
              {solutionButtons[SelectedSolution]?.header}
            </div> */}
            <img
              className="sample_page_solution_Section_main_contianer_right_section_image"
              src={solutionButtons[SelectedSolution]?.image}
            />
            {/* <div className="sample_page_solution_Section_main_contianer_right_section_footer">
              {solutionButtons[SelectedSolution]?.footer}
            </div> */}
          </div>
        </div>
      </div>

      {/* ------- Event Testimonial - Section - 1 --------------- */}

      <div className="sample_page_testimonial_section_1">
        <EventTestimonial singleCardData={testimonialData[1]} />
      </div>

      {/* fourth section preview Section */}
      <div className="sample_page_preview_Section">
        <div className="sample_page_preview_Section_left">
          <span className="sample_page_preview_Section_left_first_text">
            Witness the magic
          </span>
          <span className="sample_page_preview_Section_left_second_text">
            <span>
              Previews <br />
            </span>{" "}
            don’t lie
          </span>
          <span className="sample_page_preview_Section_left_third_text">
            See your event take shape before your eyes
          </span>
          <div className="sample_page_preview_Section_left_form">
            <TextField1
              name="sname"
              id="sname"
              value={data?.sname}
              placeholder="Event Name"
              onChange={handleChange}
              maxLength={65}
            />

            <TextField1
              name="hname"
              id="hname"
              value={data?.hname}
              placeholder="Host Name"
              onChange={handleChange}
              maxLength={65}
            />

            <DatePicker1
              name="date"
              type="date"
              id="date"
              value={data?.date}
              autoComplete="off"
              placeholder="Date"
              onChange={(date) => {
                setdata({ ...data, date: date });
              }}
            />

            <section
              style={{
                width: "100%",
                gap: "10px",
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                marginBottom: "20px",
              }}
            >
              <TextField1
                name="startTime"
                type="time"
                id="startTime"
                value={data?.startTime}
                placeholder="Enter Title Here"
                onChange={handleChange}
              />
              <TextField1
                name="endTime"
                type="time"
                id="endTime"
                value={data?.endTime}
                placeholder="Enter Title Here"
                onChange={handleChange}
              />
            </section>
            <Button4
              onClick={() => {
                mixpanel.track(
                  `${
                    localStorage.getItem("jwtToken")
                      ? "Event Side My Account"
                      : "Event Side Clicked Start my 10-day free trial (risk-free!)"
                  }`
                );
                !localStorage.getItem("jwtToken")
                  ? setOpenLoginModalFromOutside(true)
                  : navigate("/dashboard");
              }}
              icon={<FaArrowRight size={20} />}
              text={"Start my 10-day free trial (risk-free!)"}
            />
          </div>
        </div>
        <div className="sample_page_preview_Section_right">
          <div className="sample_page_preview_Section_right_preview_container_one">
            <img
              src={require("../../../../Utils/Images/mobile-screen.png")}
              alt=""
            />
            <div className="sample_page_preview_Section_right_perview_demo_mobile_view_one">
              {/* Navbar */}
              <section className="live_demo_navbar_section">
                <img
                  style={{
                    width: "auto",
                    height: "17px",
                    borderRadius: "unset",
                  }}
                  src={require("../../../../Utils/Images/logo-invite-only.png")}
                  alt=""
                />

                <button>Sign Up</button>
              </section>
              <section className="sample_page_preview_Section_live_demo_main">
                <div className="sample_page_preview_Section_live_demo_banner_container">
                  <div className="sample_page_preview_Section_live_demo_banner_inner_container">
                    <div className="sample_page_preview_Section_live_demo_banner_left">
                      <span className="sample_page_preview_Section_live_demo_banner_left_event_Name">
                        {data?.sname || "Introduction to Product Management"}
                      </span>
                      <span className="sample_page_preview_Section_live_demo_banner_left_host_name">
                        Hosted by {data?.hname || "Aesha"}
                      </span>
                    </div>
                    <div className="sample_page_preview_Section_live_demo_banner_right">
                      <div className="sample_page_preview_Section_live_demo_banner_right_date_time_container">
                        <div className="sample_page_preview_Section_live_demo_banner_right_date_container">
                          <LuCalendarDays size={8} />{" "}
                          {getDate(data?.date || "2024-03-18T18:30:00.000Z")}
                        </div>
                        <div className="sample_page_preview_Section_live_demo_banner_right_date_container">
                          <IoMdTime size={8} />{" "}
                          {convertTime(data?.startTime || "12:30") +
                            "-" +
                            convertTime(data?.endTime || "14:30")}
                        </div>
                      </div>
                      <div className="sample_page_preview_Section_live_demo_banner_right_speaker_container">
                        <span>
                          {data?.hname || "Aesha"}, <span>Speaker</span>
                        </span>
                        <img src="https://anchors-assets.s3.ap-south-1.amazonaws.com/SpeakerDummy.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="eventName">
                  {data?.sname || "Introduction to Product Management"}
                </span>
                <span className="speakerName">by {data?.hname || "Aesha"}</span>
                <button>Registe For event</button>
              </section>
            </div>
          </div>
          <div className="sample_page_preview_Section_right_preview_container_two">
            <img
              src={require("../../../../Utils/Images/mobile-screen.png")}
              alt=""
            />
            <div className="sample_page_preview_Section_right_perview_demo_mobile_view_one">
              {/* Navbar */}

              <section className="live_demo_navbar_section">
                <img
                  style={{
                    width: "auto",
                    height: "17px",
                    borderRadius: "unset",
                  }}
                  src={require("../../../../Utils/Images/logo-invite-only.png")}
                  alt=""
                />

                <button>Sign Up</button>
              </section>

              <section
                style={{ background: "#0D1117" }}
                className="sample_page_preview_Section_live_demo_main"
              >
                <span
                  style={{
                    color: "#FFF",
                    fontSize: "12px",
                    fontWeight: "700",
                    marginTop: "10px",
                    width: "90%",
                  }}
                >
                  Attendee Card
                </span>
                <div
                  style={{ marginTop: "10px" }}
                  className="sample_page_preview_Section_live_demo_banner_container"
                >
                  <div className="sample_page_preview_Section_live_demo_banner_inner_container">
                    <div
                      style={{ marginTop: "20px" }}
                      className="sample_page_preview_Section_live_demo_banner_left"
                    >
                      <div className="sample_page_preview_Section_live_demo_banner_left_attendee_container">
                        <div className="sample_page_preview_Section_live_demo_banner_left_attendee_image">
                          R
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3.13px",
                          }}
                        >
                          <span className="sample_page_preview_Section_live_demo_banner_left_attendee_name">
                            Yagyesh Bobde.
                          </span>
                          <span className="sample_page_preview_Section_live_demo_banner_left_attendee_info">
                            attending this event.
                          </span>
                        </div>
                      </div>
                      <span className="sample_page_preview_Section_live_demo_banner_left_event_Name">
                        {data?.sname || "Introduction to Product Management"}
                      </span>
                      <span className="sample_page_preview_Section_live_demo_banner_left_host_name">
                        Hosted by {data?.hname || "Aesha"}
                      </span>
                    </div>
                    <div className="sample_page_preview_Section_live_demo_banner_right">
                      <div className="sample_page_preview_Section_live_demo_banner_right_date_time_container">
                        <div className="sample_page_preview_Section_live_demo_banner_right_date_container">
                          <LuCalendarDays size={8} />{" "}
                          {getDate(data?.date || "2024-03-18T18:30:00.000Z")}
                        </div>
                        <div className="sample_page_preview_Section_live_demo_banner_right_date_container">
                          <IoMdTime size={8} />{" "}
                          {convertTime(data?.startTime || "12:30") +
                            "-" +
                            convertTime(data?.endTime || "14:30")}
                        </div>
                      </div>
                      <div className="sample_page_preview_Section_live_demo_banner_right_speaker_container">
                        <span>
                          {data?.hname || "Aesha"}, <span>Speaker</span>
                        </span>
                        <img src="https://anchors-assets.s3.ap-south-1.amazonaws.com/SpeakerDummy.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sample_page_preview_Section_live_demo_referral_beneifts">
                  <img src="https://anchors-assets.s3.ap-south-1.amazonaws.com/ReferralBenefits.png" />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* fifth Section referral system */}
      <div className="sample_page_referral_Section">
        <span className="sample_page_referral_Section_header">
          Boost conversions with
        </span>
        <span className="sample_page_referral_Section_header2">
          Our Gamified Referral System
        </span>
        <div className="sample_page_referral_Section_refereral_dashboard">
          <div className="sample_page_referral_Section_refereral_dashboard_left_section">
            <span className="sample_page_referral_Section_refereral_dashboard_left_section_first_text">
              Witness a <span>50% Surge</span> in Your Event Registrations!
            </span>
            <span className="sample_page_referral_Section_refereral_dashboard_left_section_second_text">
              Turn your event into a friendly competition{" "}
            </span>
            <span className="sample_page_referral_Section_refereral_dashboard_left_section_third_text">
              How it works?
            </span>
            <span className="sample_page_referral_Section_refereral_dashboard_left_section_fourth_text">
              Attendees invite friends via their referral link. <br /> Higher
              the referrals, higher the rank.
            </span>
          </div>
          <div className="sample_page_referral_Section_refereral_dashboard_right_section">
            <img src="https://anchors-assets.s3.ap-south-1.amazonaws.com/referralDashboard.png" />
          </div>
        </div>
        <span className="sample_page_referral_Section_bottom_subtext">
          Referral System
        </span>
        <span className="sample_page_referral_Section_bottom_text">
          Referral Works <span>Like Magic</span>
        </span>
        <div className="sample_page_referral_Section_referral_video">
          <img src="https://anchors-assets.s3.ap-south-1.amazonaws.com/referral_game.gif" />
          {/* <video
            id="video"
            className="rounded-[10px]"
            width="100%"
            loop={true}
            autoPlay={true}
            muted
            src="https://anchors-assets.s3.ap-south-1.amazonaws.com/referral_game.gif"
          ></video> */}
        </div>
      </div>

      {/* ------- Event Testimonial - Section - 2 --------------- */}

      <div className="sample_page_testimonial_section_2">
        <EventTestimonial singleCardData={testimonialData[0]} />
      </div>

      {/*Sixth section pricing section*/}
      <NewPricing />

      {/* <div className="productHunt_floater_landing_page">
        <a
          href="https://www.producthunt.com/posts/events-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-events&#0045;2"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=418357&theme=light"
            alt="Events - Get&#0032;50&#0037;&#0032;More&#0032;Conversion&#0032;with&#0032;70&#0037;&#0032;Less&#0032;Effort&#0032;in&#0032;your&#0032;events | Product Hunt"
            style={
              window.screen.width > 600
                ? { width: "250px", height: "54px" }
                : { width: "123px", height: "unset" }
            }
            width="250"
            height="54"
          />
        </a>
      </div> */}

      <ToastContainer theme="dark" limit={1} />
    </div>
  );
}



export default Sample;
