// Image1.js
import React from 'react';

const Image5 = ({textcolor}) => {
  return (
    <svg className='imgComponent5' width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.1027 10.0667C14.8343 9.71667 14.5077 9.41333 14.2043 9.11C13.4227 8.41 12.536 7.90833 11.7893 7.17333C10.051 5.47 9.66599 2.65833 10.7743 0.5C9.66599 0.768333 8.69766 1.375 7.86933 2.04C4.84766 4.46667 3.65766 8.74833 5.08099 12.4233C5.12766 12.54 5.17433 12.6567 5.17433 12.8083C5.17433 13.065 4.99933 13.2983 4.76599 13.3917C4.49766 13.5083 4.21766 13.4383 3.99599 13.2517C3.92938 13.1966 3.87394 13.1293 3.83266 13.0533C2.51433 11.385 2.30433 8.99333 3.19099 7.08C1.24266 8.66667 0.180992 11.35 0.332659 13.8817C0.402659 14.465 0.472659 15.0483 0.670992 15.6317C0.834326 16.3317 1.14933 17.0317 1.49933 17.65C2.75933 19.6683 4.94099 21.115 7.28599 21.4067C9.78266 21.7217 12.4543 21.2667 14.3677 19.54C16.5027 17.6033 17.2493 14.5 16.1527 11.84L16.001 11.5367C15.756 11 15.1027 10.0667 15.1027 10.0667ZM11.416 17.4167C11.0893 17.6967 10.5527 18 10.1327 18.1167C8.82599 18.5833 7.51933 17.93 6.74933 17.16C8.13766 16.8333 8.96599 15.8067 9.21099 14.7683C9.40933 13.835 9.03599 13.065 8.88433 12.1667C8.74433 11.3033 8.76766 10.5683 9.08266 9.76333C9.30433 10.2067 9.53766 10.65 9.81766 11C10.716 12.1667 12.1277 12.68 12.431 14.2667C12.4777 14.43 12.501 14.5933 12.501 14.7683C12.536 15.725 12.116 16.775 11.416 17.4167Z" fill={textcolor||"#FAFAFA"}/>
</svg>
  );
};

export default Image5;
