// import "../Sample.css";
import styles from "./event_testimonial.module.css";
import { Pagination, Autoplay, EffectFlip, EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { LiaQuoteLeftSolid } from "react-icons/lia";

export const testimonialData = [
  {
    id: 1,
    name: "Priyanka Bhatia",
    designation: "Co-founder & Lead Consultant",
    company: "@Women on Wealth",
    image:
      "https://anchors-assets.s3.ap-south-1.amazonaws.com/eventTestimonialtwo.png",
    testimonial: (
      <p>
        I can't thank anchors|Events enough for their exceptional support. They
        offer not only top-notch technology but also a <b>personal touch</b>,
        which is invaluable. Their prompt assistance with graphics and banners,
        all seamlessly integrated into the system was impressive. It all made a{" "}
        <b>significant impact</b> in advancing our mission of empowering women
        financially. Highly recommend for anyone looking for a
        <b> professional, user-friendly solution</b>.
      </p>
    ),
  },
  {
    id: 2,
    name: "Dr. Divya Rajput",
    designation: "CEO, JSS STEP",
    company: "",
    image:
      "https://anchors-assets.s3.ap-south-1.amazonaws.com/eventTestimonialOne.png",
    testimonial: (
      <p>
        I launched my master coaching program using anchors|Events. The platform
        is <b>super convenient</b>; it took me only 30 minutes to host my
        webinar. The team is diligent and <b>super responsive</b>; they even
        helped me make last-minute edits to the date post-launch. I{" "}
        <b>highly recommend</b> anchors to all creators - go live with an
        AWESOME, super professional look and feel without burning a hole in your
        pocket.
      </p>
    ),
  },
];

export const EventTestimonial = ({ singleCardData }) => {
  return (

    <>
      {/* ----- Single Testimonial Card */}
      <TestimonialCard {...singleCardData} />

      {/* ----- Slider Testimonial */}
      {/* <div className={`${styles.sample_page_testimonial_section}`}>
        <Swiper
          effect={"slide"}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay, EffectFlip, Pagination, EffectCards]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className={`${styles.testimonial_swiper_container}`}
        >
          {testimonialData.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <TestimonialCard {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </>
  );
};

const TestimonialCard = ({
  name,
  designation,
  company,
  image,
  testimonial,
}) => {
  return (
    <div className={`${styles.testimonial_conatiner_card}`}>
      <div className={`${styles.testimonial_card_body}`}>
        <div className={`${styles.big_quote_icon}`}>
          <LiaQuoteLeftSolid color="#616161" />
        </div>
        <div className={`${styles.body_content}`}>{testimonial}</div>
        <div className={`${styles.tesimonial_card_footer}`}>
          <div className={`${styles.card_user_name}`}>{name}</div>
          <div className={`${styles.card_user_tag}`}>
            <div>{designation}</div>
            <div>{company}</div>
          </div>
        </div>
      </div>
      <div className={`${styles.testimonial_card_image_holder}`}>
        <div className={`${styles.testimonial_card_image}`}>
          <img src={image} alt={`${name} testimonial`} />
        </div>
      </div>
    </div>
  );
};
