// Image1.js
import React from 'react';

const Image4 = ({textcolor}) => {
  return (
    <svg className='imgComponent4' width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 27.5908C0 27.5908 17.0669 29.0419 22.9598 22.8995C28.8527 16.757 26.4704 -0.000483081 26.4704 -0.000483081C26.4704 -0.000483081 26.8679 16.558 32.7818 22.2317C38.6957 27.9053 54.4275 26.8209 54.4275 26.8209C54.4275 26.8209 38.9197 26.294 33.141 32.3175C27.3622 38.3409 27.9572 54.4122 27.9572 54.4122C27.9572 54.4122 28.4799 38.8751 22.4054 33.0474C16.3308 27.2197 0 27.5908 0 27.5908Z" fill={textcolor||"white"} fill-opacity="0.5"/>
    <path d="M32.3184 64.0674C32.3184 64.0674 38.0497 64.5547 40.0286 62.492C42.0076 60.4292 41.2075 54.8018 41.2075 54.8018C41.2075 54.8018 41.341 60.3624 43.327 62.2677C45.313 64.173 50.596 63.8088 50.596 63.8088C50.596 63.8088 45.3882 63.6319 43.4476 65.6547C41.507 67.6774 41.7068 73.0744 41.7068 73.0744C41.7068 73.0744 41.8824 67.8568 39.8424 65.8998C37.8025 63.9427 32.3184 64.0674 32.3184 64.0674Z" fill={textcolor||"white"} fill-opacity="0.5"/>
    <path d="M48.9531 42.5195C48.9531 42.5195 56.5666 43.1669 59.1954 40.4267C61.8242 37.6866 60.7614 30.2112 60.7614 30.2112C60.7614 30.2112 60.9388 37.5979 63.5769 40.1288C66.2151 42.6598 73.233 42.1761 73.233 42.1761C73.233 42.1761 66.315 41.941 63.7372 44.6281C61.1593 47.3151 61.4247 54.4844 61.4247 54.4844C61.4247 54.4844 61.6579 47.5534 58.948 44.9537C56.2382 42.354 48.9531 42.5195 48.9531 42.5195Z" fill={textcolor||"white"} fill-opacity="0.5"/>
    </svg>



  );
};

export default Image4;
