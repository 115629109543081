import React from "react";
import "./PurchaseCredits.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { paymentContext } from "../../../Context/PaymentState";
import { creatorContext } from "../../../Context/CreatorState";
import { toast } from "react-toastify";
import { Button4 } from "../../../Components/Editor/New UI/Create Services/InputComponents/buttons";
import { GoArrowRight } from "react-icons/go";
import { CheckOutSuccess } from "../../../Components/Pricing/PricingCheckOutSuccess";
import { LoadThree } from "../../../Components/Modals/Loading";

export const PurchaseCredits = ({ setOpenCredits }) => {
  const [Amount, SetAmount] = useState(0);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [Credits, SetCredits] = useState(null);
  const [Gst, SetGst] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const { CreateCreditsOrder, VerifyCreditsOrder, getEaseBuzz } =
    useContext(paymentContext);
  const { getAllCreatorInfo, allCreatorInfo, basicNav } =
    useContext(creatorContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.value < 0) {
      return SetCredits(0);
    }
    SetCredits(e.target.value);
    SetAmount(e.target.value);
  };

  useEffect(() => {
    SetGst((Credits * 5 * 18) / 100);
  }, [Credits]);
  useEffect(() => {
    SetAmount(Credits * 5 + Gst);
  }, [Gst]);

  const handlePaymentResponse = async (response, orderId) => {
    setPaymentProcessing(true);
    setLoading(false);

    try {
      switch (response.status) {
        // 1. user cancelled the payment mode
        case "userCancelled":
          setPaymentProcessing(false);
          setLoading(false);
          toast.error("You cancelled the Order!", {
            position: "top-center",
            autoClose: 1000,
          });
          break;

        //2. payment dropping by user --- dropped the payment by the user
        case "dropped":
          setPaymentProcessing(false);
          setLoading(false);
          toast.error("You Dropped the Order!", {
            position: "top-center",
            autoClose: 1000,
          });
          break;

        //  2. payment failed due to any reasone
        case "failure":
          setPaymentProcessing(false);
          setLoading(false);
          toast.error(
            "Payment Failed, if amount got deducted inform us at info@anchors.in",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;

        //  3. Payment pending due to any reason
        case "pending":
          // Inform lark bot about the failure
          setPaymentProcessing(false);
          setLoading(false);
          toast.error(
            "Payment is still pending, complete the payment to proceed,for issues inform us at info@anchors.in",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;

        // 4. success payment
        case "success":
          setPaymentProcessing(false);
          setLoading(false);

          let result = await VerifyCreditsOrder({
            orderId,
            paymentData: response,
            Credits,
          });
          if (result?.success) {
            toast.success("Order Placed Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            setPaymentSuccess(true);
          } else {
            throw new Error("Some error occured");
          }
          break;

        // Else all cases  -----------------
        default:
          setPaymentProcessing(false);
          setLoading(false);

          toast.error(
            "The order is not placed. Try again!!! ,in case of issues inform us at info@anchors.in ",
            {
              position: "top-center",
              autoClose: 1000,
            }
          );
          break;
      }
    } catch (error) {
      toast.error(
        "The order is not placed. Try again!!! ,in case of issues inform us at info@anchors.in ",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
    }
  };

  const handlePaymentClick = async () => {
    setPaymentProcessing(true);
    setLoading(true);

    try {
      if (Amount === 0 || !Amount) {
        setPaymentProcessing(false);
        setLoading(false);
        return toast.error("Please Enter Amount", {
          position: "top-center",
          autoClose: 1000,
        });
      }

      const order = await CreateCreditsOrder({
        productName: "Events Credits Bought",
        amount: Amount,
        gst: Gst,
        netToPay: Credits * 5,
        userName: basicNav?.name,
        userEMail: basicNav?.email,
        subject: "Recharge Credits",
        planID: "",
        userPhone: allCreatorInfo?.phone ?? "8799710137",
        gstInNumber: "",
        couponName: "",
        couponDiscount: "",
      });

      if (order?.error) {

        setLoading(false);

        return toast.error(order?.error, {
          position: "top-center",
          autoClose: 1000,
        });
      }

      if (Amount > 0) {
        const key = await getEaseBuzz();

        const easebuzzCheckout = new window.EasebuzzCheckout(key, "prod");

        const options = {
          access_key: order?.paymentData.data,
          onResponse: (response) => {
            // handling the edge cases of the response
            handlePaymentResponse(response, order?.order ?? "transparent");
          },
          theme: "#000000", // color hex
        };

        easebuzzCheckout.initiatePayment(options);
      } else {
      }
    } catch (error) {
      toast.error("Error in proccessing the payment", {
        position: "top-center",
        autoClose: 1000,
      });
      setPaymentProcessing(false);
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadThree />}
      {paymentSuccess ? (
        <CheckOutSuccess
          showNavbar={false}
          Header={`${Credits || 0} Credits added to your account`}
          onClick={() => setOpenCredits(false)}
        />
      ) : (
        <div className="eventsPurchaseCreditsContainer">
          <p className="eventsPurchaseCreditsHeader ">
            Please top up your credits
          </p>
          <p className="eventsPurchaseCreditsSubheader">
            No credits mean users will not be able to register for your events.{" "}
            <br /> To avoid this, top up your credits quickly. Valid for the
            duration of your current plan
          </p>
          <div className="eventsPurchaseMainContainter">
            <p>Choose the number of credits you want to add</p>
            <div className="eventsPurchaseCreditsMainContainerOptions">
              <Button4
                onClick={() => {
                  SetCredits(200);
                }}
                text="200"
                icon={undefined}
                rightIcon={undefined}
                background={Credits === 200 ? "#121212" : "transparent"}
                textColor={Credits === 200 ? "#FAFAFA" : "#757575"}
                borderColor={"#BDBDBD"}
              />
              <Button4
                onClick={() => {
                  SetCredits(300);
                }}
                text="300"
                icon={undefined}
                rightIcon={undefined}
                background={Credits === 300 ? "#121212" : "transparent"}
                textColor={Credits === 300 ? "#FAFAFA" : "#757575"}
                borderColor={"#BDBDBD"}
              />
              <Button4
                onClick={() => {
                  SetCredits(500);
                }}
                text="500"
                icon={undefined}
                rightIcon={undefined}
                background={Credits === 500 ? "#121212" : "transparent"}
                textColor={Credits === 500 ? "#FAFAFA" : "#757575"}
                borderColor={"#BDBDBD"}
              />
              <Button4
                onClick={() => {
                  SetCredits(1000);
                }}
                text="1000"
                icon={undefined}
                rightIcon={undefined}
                background={Credits === 1000 ? "#121212" : "transparent"}
                textColor={Credits === 1000 ? "#FAFAFA" : "#757575"}
                borderColor={"#BDBDBD"}
              />
              <input
                className="eventsPurchaseCreditsCustomInput"
                type="Number"
                placeholder="custom"
                min={0}
                value={Credits}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <p className="eventsPurchaseCreditsOrderSummaryheader">
              Order Summary
            </p>
            <div className="eventPurchaseCredtisCreditsOrderSummaryRow">
              <p className="w-[136px]">{`Cost of Credits`}</p>
              <p className="w-[40px]">:</p>
              <p>
                <b>{`₹ ${Credits * 5 || 0}`}</b>
              </p>
            </div>
            <div className="eventPurchaseCredtisCreditsOrderSummaryRow">
              <p className="w-[136px]">{`GST ( 18% )`}</p>
              <p className="w-[40px]">:</p>
              <p>
                <b>{`₹ ${Gst || 0}`}</b>
              </p>
            </div>
            <div className="eventPurchaseCredtisCreditsOrderSummaryRow">
              <p className="w-[136px]">{`Total Amount`}</p>
              <p className="w-[40px]">:</p>
              <p>
                <b>{`₹ ${Amount || 0}`}</b>
              </p>
            </div>
            <Button4
              onClick={handlePaymentClick}
              text={`CONTINUE TO PAY ₹${Amount || 0}`}
              icon={<GoArrowRight />}
              rightIcon={undefined}
              background={undefined}
              textColor={undefined}
              borderColor={undefined}
            />
          </div>
        </div>
      )}
    </>
  );
};
