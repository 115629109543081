import React from "react";

const Image4 = () =>{
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.16667 12V8H11.8333V12H10.5V9.33333H2.5V12H1.16667ZM1.5 7.33333C1.22222 7.33333 0.986222 7.23622 0.792 7.042C0.597778 6.84778 0.500444 6.61156 0.5 6.33333C0.5 6.05556 0.597333 5.81956 0.792 5.62533C0.986667 5.43111 1.22267 5.33378 1.5 5.33333C1.77778 5.33333 2.014 5.43067 2.20867 5.62533C2.40333 5.82 2.50044 6.056 2.5 6.33333C2.5 6.61111 2.40289 6.84733 2.20867 7.042C2.01444 7.23667 1.77822 7.33378 1.5 7.33333ZM3.16667 7.33333V1.33333C3.16667 0.966667 3.29733 0.652889 3.55867 0.392C3.82 0.131111 4.13378 0.000444444 4.5 0H8.5C8.86667 0 9.18067 0.130667 9.442 0.392C9.70333 0.653333 9.83378 0.967111 9.83333 1.33333V7.33333H3.16667ZM11.5 7.33333C11.2222 7.33333 10.9862 7.23622 10.792 7.042C10.5978 6.84778 10.5004 6.61156 10.5 6.33333C10.5 6.05556 10.5973 5.81956 10.792 5.62533C10.9867 5.43111 11.2227 5.33378 11.5 5.33333C11.7778 5.33333 12.014 5.43067 12.2087 5.62533C12.4033 5.82 12.5004 6.056 12.5 6.33333C12.5 6.61111 12.4029 6.84733 12.2087 7.042C12.0144 7.23667 11.7782 7.33378 11.5 7.33333ZM4.5 6H8.5V1.33333H4.5V6Z" fill="#FF5C5C"/>
        </svg>
    )
}

export default Image4;